import React from 'react';

import styled, { useTheme, css } from 'styled-components';

import { ResponsivePXValue } from '../Theme';

export type TitleVariant = 't1' | 't2' | 't3' | 't4';
export type TitleAlignment = 'left' | 'center' | 'right' | 'justify';
export type TitleTransforms = 'none' | 'capitalize' | 'uppercase' | 'lowercase';
export type TitleDecorations = 'none' | 'underline' | 'overline' | 'line-through';

const T = styled.h1<{ align: string; decoration: string; transform: string; color?: string; variant: string }>`
	color: ${(props): string => (props.color ? props.color : props.theme.colors.black.pureBlack)};
	font-family: monospace;
	font-weight: 700;
	text-align: ${(props): string => props.align};
	text-decoration: ${(props): string => props.decoration};
	text-transform: ${(props): string => props.transform};
	margin: 0;

	${(props) =>
		props.variant === 't1' &&
		css`
			font-weight: 700;
			${ResponsivePXValue('font-size', { mobile: '5vw', tablet: '32px', desktop: '38px' })}
		`}

	${(props) =>
		props.variant === 't2' &&
		css`
			${ResponsivePXValue('font-size', { mobile: '5vw', tablet: '28px', desktop: '28px' })}
		`}
	${(props) =>
		props.variant === 't3' &&
		css`
			${ResponsivePXValue('font-size', { mobile: '5vw', tablet: '28px', desktop: '20px' })}
		`}
	${(props) =>
		props.variant === 't4' &&
		css`
			${ResponsivePXValue('font-size', { mobile: '3vw', tablet: '28px', desktop: '20px' })}
		`}
`;
export interface TitleProps {
	children: React.ReactNode | string | any;
	variant?: TitleVariant;
	align?: TitleAlignment;
	transform?: TitleTransforms;
	decoration?: TitleDecorations;
	color?: string;
	skeleton?: boolean;
	className?: string;
	onClick?: () => void;
}

const Title: React.FC<TitleProps> = (props) => {
	const theme = useTheme();
	const {
		children,
		variant = 't1',
		align = 'left',
		transform = 'none',
		decoration = 'none',
		onClick,
		color = theme.colors.white.pureWhite,
		className
	} = props;

	const _handleLinkClick = () => {
		if (onClick) {
			return onClick();
		}
	};

	return (
		<T
			align={align}
			variant={variant}
			decoration={decoration}
			transform={transform}
			color={color}
			className={className}
			onClick={() => _handleLinkClick?.()}
		>
			{children}
		</T>
	);
};

export default Title;
