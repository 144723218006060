import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './components/pages/HomePage';
import { AuthProvider, useAuth } from './services/AuthProvider';
import BookingsPage from './components/pages/BookingsPage';
import AdminPage from './components/pages/admin/AdminPage';
import AdminMembersPage from './components/pages/admin/AdminMembersPage';
import KampioenskappePage from './components/pages/admin/kampioenskappePage';
import StatsPage from './components/pages/StatsPage';

const App: React.FC = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<NavigateRoute />} />
          <Route path="/bookings" element={<BookingsPage />} />
          <Route path="/stats" element={<PrivateRoute component={<StatsPage />} />} />
          <Route path="/admin" element={<PrivateAdminRoute component={<AdminPage />} />} />
          <Route path="/admin/members" element={<PrivateAdminRoute component={<AdminMembersPage />} />} />
          <Route path="/admin/kampioenskappe" element={<PrivateAdminRoute component={<KampioenskappePage />} />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

const Home: React.FC = () => <h1>Home Page</h1>;
const Bookings: React.FC = () => <h1>Bookings Page</h1>;

interface PrivateAdminRouteProps {
  component: JSX.Element;
}

const PrivateAdminRoute: React.FC<PrivateAdminRouteProps> = ({ component }) => {
  const { hasPermission } = useAuth();
  return hasPermission(1) ? component : <Navigate to="/bookings" />;
};

const PrivateRoute: React.FC<PrivateAdminRouteProps> = ({ component }) => {
  const { isSignedIn } = useAuth();
  return isSignedIn ? component : <Navigate to="/" />;
};

const NavigateRoute: React.FC = () => {
  const { isSignedIn } = useAuth();
  return isSignedIn ? <Navigate to="/bookings" /> : <HomePage />;
};

export default App;
