import React, { ChangeEvent, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { ResponsivePXValue, theme } from '../../Theme';
import AdminHeader from '../../organisms/AdminHeader';
import Title from '../../atoms/Title';
import Paragraph from '../../atoms/Paragraph';
import axios from 'axios';
import update from 'react-addons-update';
import Button from '../../atoms/Button';

const Wrapper = styled.div`
	width: 100vw;
	${ResponsivePXValue('padding-top', { mobile: '60px', tablet: '80px', desktop: '80px' })}
`
const SubHeader = styled.div`
	display: flex;
	justify-content: space-between;
	${ResponsivePXValue('gap', { mobile: '5px', tablet: '30px', desktop: '30px' })}
	${ResponsivePXValue('padding', { mobile: '5px', tablet: '30px', desktop: '30px' })}
	button {
		width: 200px; 
	}
`


const Body = styled.div`
	${ResponsivePXValue('padding', { mobile: '0 5px', tablet: '0 30px', desktop: '0 30px' })}
`

const Table = styled.table`
	width: 100%;
	${ResponsivePXValue('display', { mobile: 'block', tablet: 'table', desktop: 'table' })}
    overflow: scroll;
	thead {
		display: inline-table;
		width: 100%;
		tr {
			background-color: #ccc;
			
			td, th {
				p {
					font-weight: 700;
				}
			}
		}
	}
	tbody {
		display: inline-table;
		width: 100%;
	}
	tr {
		
		&:hover {
				background-color: #ccc;
			}
	}
	td, th {
	border-bottom: 1px solid #ccc;
		${ResponsivePXValue('padding', { mobile: '5px', tablet: '10px', desktop: '10px' })}
	}
`

type Member = {
	id: number;
	first_name: string;
	last_name: string;
	member_number: string;
	member: number;
	paid: number;
};

interface AdminKampPageProps {
	members: Member[];
}

const DEFAULT_STATE: AdminKampPageProps = {
	members: [],
};

const KampioenskappePage: React.FC = () => {
	
	const [state, setState] = useState<AdminKampPageProps>({ ...DEFAULT_STATE });

	const _handlePaid = (id:number) => {
		axios.post(process.env.REACT_APP_API_URL + 'kampioenskappe/inskrywing/' + id, {paid:1})
			.then(response => {
				getEntries();
			})
			.catch(error => {
				console.log('error', error)
			});
	};

	const getEntries = () => {
		axios.get(process.env.REACT_APP_API_URL + 'kampioenskappe').then((response: any) => {
			setState((prevState) =>
				update(prevState, {
					members: { $set: response.data },
				})
			);
		});
	}

	useEffect(() => {
		getEntries();
	},[]);

	return (<Wrapper>
		<AdminHeader />
		<SubHeader>
			<Title variant="t1" color={theme.colors.black.pureBlack}>Liga 2024</Title>
			<Paragraph color='#000' >Total: {state.members.length}</Paragraph>
		</SubHeader>
		<Body>
			<Table width="100%" className="sortable">
				<thead>
					<tr>
						<th><Paragraph color="#000">Number</Paragraph></th>
						<th><Paragraph color="#000">Name</Paragraph></th>
						<th><Paragraph color="#000">Paid</Paragraph></th>
					</tr>
				</thead>
				<tbody>
					{state.members.map((member, memberIndex: any) => (
						<tr key={memberIndex}>
							<td><Paragraph color="#000">{member.member_number}</Paragraph></td>
							<td><Paragraph color="#000">{member.first_name} {member.last_name}</Paragraph></td>
							<td>
							{member.paid == 1 ? (
									<svg fill="#0cb300" height="20px" width="20px" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="#0cb300"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="active"> <path d="M8.6,20.1l-7.8-8l1.4-1.4l6.4,6.5L21.8,3.9l1.4,1.4L8.6,20.1z"></path> </g> </g></svg>
								) : (
									<Button colors={{ color: '#fff', backgroundColor: '#009bff' }} title={'Paid'} onClick={() => _handlePaid(member.id)} />
								)}
							</td>
						</tr>
					))}

				</tbody>
			</Table >
		</Body>
	</Wrapper >);
};

export default KampioenskappePage;
