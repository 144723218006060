import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

interface User {
    id: number;
    username: string;
    first_name: string;
    last_name: string;
    member_number: string;
    email: string;
    contact: string;
    admin: number;
  }

interface AuthContextType {
  isSignedIn: boolean;
  user: User | null;
  signIn: (user: User) => void;
  signOut: () => void;
  hasPermission: (requiredRole: number) => boolean;
  updateUserSession: (userData: User) => void;
}


const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isSignedIn, setIsSignedIn] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    // Replace the following line with your actual authentication check logic
    const user = localStorage.getItem('user');
    if(user) {
        setUser(JSON.parse(user));
    }
    setIsSignedIn(!!user);
  }, []);

  const hasPermission = (requiredRole: number) => {
    return user?.admin === requiredRole;
  };

  const signIn = (user: User) => {
    localStorage.setItem('user', JSON.stringify(user));
    setIsSignedIn(true);
    setUser(user);
  };

  const signOut = () => {
    localStorage.removeItem('user');
    setIsSignedIn(false);
    setUser(null);
  };

  const updateUserSession = (userData: User) => {
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
  };


  const value = {
    isSignedIn,
    user,
    signIn,
    signOut,
    hasPermission,
    updateUserSession
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
