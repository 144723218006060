import React from 'react';
import styled, { useTheme } from 'styled-components';
import { ResponsivePXValue } from '../Theme';
import Title from '../atoms/Title';
import date from 'date-and-time';
import HeaderNavigation from './HeaderNavigation';
import Paragraph from '../atoms/Paragraph';
import { useAuth } from '../../services/AuthProvider';

const NavBar = styled.div`
	top: 0;
	left: 0;
	width: 100vw;
	background-color: #009bff;
	position: fixed;
	z-index: 1;
`
const NavBarInner = styled.div`
	display: flex;
	justify-content: space-between;
    align-items: center;
	box-shadow: 0px 0px 7px #ccc;

	${ResponsivePXValue('padding', { mobile: '10px 15px', tablet: '20px 30px', desktop: '20px 30px' })}

	h1 {
		${ResponsivePXValue('width', { mobile: '90px', tablet: '', desktop: '' })}

	}
`

const Nav = styled.div`
	display: flex;
    align-items: end;
    flex-direction: column;
    gap: 10px;
	a {
		color: #FFF;
		font-family: monospace;
		font-weight: 700;
		text-align: center;
		text-decoration: none;
		text-transform: none;
		margin: 0;
		${ResponsivePXValue('font-size', { mobile: '3vw', tablet: '28px', desktop: '20px' })}
	}
`
const NavTop = styled.div`
	display: flex;
    align-items: center;
    flex-direction: row;
    gap: 30px;
	
`



const DateControls = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	${ResponsivePXValue('gap', { mobile: '5px', tablet: '20px', desktop: '30px' })}
	svg {
		${ResponsivePXValue('width', { mobile: '20px', tablet: '25px', desktop: '25px' })}
		cursor: pointer;
	}

`

interface HeaderProps {
	date?: Date;
	setDate?: (date: Date) => void;
}

const Header: React.FC<HeaderProps> = (props) => {

	const { user } = useAuth();
	const theme = useTheme();
	const today = new Date();
	const _handleDateClick = (action: string) => {
		let newDate = new Date()
		if (props.date && props.setDate) {
			if (action === '-') {
				newDate = date.addDays(props.date, -1);
			} else if (action === 't') {
				newDate = new Date()
			} else if (action === '+') {
				newDate = date.addDays(props.date, +1);
			}
			props.setDate(newDate)
		}
	};

	return (
		<NavBar>
			<NavBarInner>
				<Title variant='t1' align='left' color={theme.colors.white.pureWhite}>MALMESBURY SQUASH</Title>
				<Nav>
					<NavTop>
						{user?.first_name ? 
							<Paragraph>Hi, {user?.first_name} {user?.last_name}</Paragraph>
							: ('')
						}
						<HeaderNavigation/>
					</NavTop>
					

					{props.date ? (
						<DateControls>
							<svg onClick={() => _handleDateClick('-')} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M18 17L13 12L18 7M11 17L6 12L11 7" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
							<Title variant='t4' align='center' onClick={() => _handleDateClick('t')} color={theme.colors.white.pureWhite}>{(date.format(props.date, 'DD MMMM YYYY')) === date.format(today, 'DD MMMM YYYY') ? 'Today' : date.format(props.date, 'dd DD MMMM')}</Title>
							<svg onClick={() => _handleDateClick('+')} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M6 17L11 12L6 7M13 17L18 12L13 7" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
						</DateControls>
					) : ('')}

				</Nav>
			</NavBarInner>
		</NavBar >
	);
};

export default Header;
