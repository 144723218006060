import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import Button from '../atoms/Button';
import Title from '../atoms/Title';
import update from 'react-addons-update';
import { ResponsivePXValue } from '../Theme';
import axios from 'axios';
import Messages from '../atoms/Messages';
import { useAuth } from '../../services/AuthProvider';

const Wrapper = styled.div`
	width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
`
const InnerWrapper = styled.div`
	form {
		background-color: #fff;
		display: flex;
		gap: 20px;
		flex-direction: column;
		${ResponsivePXValue('width', { mobile: '70vw', tablet: '500px', desktop: '500px' })}
		${ResponsivePXValue('padding', { mobile: '30px', tablet: '30px', desktop: '30px' })}
		${ResponsivePXValue('border-radius', { mobile: '10px', tablet: '10px', desktop: '10px' })}
	}
`
const Input = styled.input`
	padding: 15px;
	font-size: 20px;
    width: -webkit-fill-available;
`
interface MemberLoginProps {
}
interface DefaultProps {
	member_number: string;
	message: {
		[key: string]: any;
	}
}
const DEFAULT_STATE: DefaultProps = {
	member_number: '',
	message: {}
};

const MemberLogin: React.FC<MemberLoginProps> = (props) => {

	const [state, setState] = useState<DefaultProps>({ ...DEFAULT_STATE });
	const { signIn } = useAuth();

	const handleLogin = (e: React.FormEvent) => {
		e.preventDefault();
		setState((prevState) =>
			update(prevState, {
				message: {
					$set: {}
				},
			})
		);

		if (!state.member_number) {
			setState((prevState) =>
				update(prevState, {
					message: {
						$set: {
							type: 'error',
							message: 'Member number is empty.'
						}
					},
				})
			);
			return false;
		}

		axios.get(process.env.REACT_APP_API_URL + 'member/' + state.member_number).then((response: any) => {
			if (response.data) {
				if (response.data.active === 1) {
					signIn(response.data);
				} else {
					setState((prevState) =>
						update(prevState, {
							message: {
								$set: {
									type: 'error',
									message: 'User is not active, please contact management.'
								}
							},
						})
					);
				}
			} else {
				setState((prevState) =>
					update(prevState, {
						message: {
							$set: {
								type: 'error',
								message: 'User does not exist.'
							}
						},
					})
				);
			}
		});
	};

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setState((prevState) =>
			update(prevState, {
				[name]: { $set: value }
			})
		);
	};

	return (
		<Wrapper>
			<InnerWrapper>
				<form onSubmit={handleLogin} id="loginForm">
					<Title color="#000" variant='t2' >Member Login</Title>
					<Messages message={state.message} />

					<Input type='text' name="member_number" placeholder='Member Number' value={state.member_number} onChange={handleChange} minLength={4} maxLength={4} />
					<Button colors={{ color: '#fff', backgroundColor: '#009bff' }} title={'Login'} type="submit" formId="loginForm" />
				</form>
			</InnerWrapper>

		</Wrapper>
	);
};

export default MemberLogin;
