import memoize from 'fast-memoize';
// @ts-ignore
import { css, DefaultTheme, FlattenInterpolation, LayoutSizeClass, ThemeProps } from 'styled-components';

import { DeviceTypeEnum } from './globalTypes';

import { colors } from './colors';

interface DeviceSizeClass extends LayoutSizeClass {
	ultra: number;
	desktop: number;
	tablet: number;
	mobile: number;
}

const cahcedStyles: { [k: string]: string } = {};
const VW_PRECISION = 3;
const MAX_WIDTH = 1440;

const breakpointSize: DeviceSizeClass = {
	ultra: MAX_WIDTH,
	desktop: 481,
	tablet: 480,
	mobile: 480
};

const calculationSize: LayoutSizeClass = {
	desktop: 1440,
	tablet: 320,
	mobile: 320
};

const pagePadding: LayoutSizeClass = {
	desktop: 80,
	tablet: 30,
	mobile: 15
};

interface SizeClasses {
	ultra: string;
	desktop: string;
	tablet: string;
	mobile: string;
	ultraImage: number;
	desktopImage: number;
	tabletImage: number;
	mobileImage: number;
	[k: string]: any;
}

export interface FormFactor {
	mobile?: string;
	tablet?: string;
	desktop?: string;
	ultra?: string | number;
}

const sizeClasses: SizeClasses = {
	ultra: '',
	desktop: '',
	tablet: '',
	mobile: '',
	ultraImage: 2000,
	desktopImage: 1600,
	tabletImage: 1024,
	mobileImage: 768
};

// @ts-ignore
Object.keys(breakpointSize).forEach((key: keyof DeviceSizeClass) => {
	const val: number = breakpointSize[key] as number;
	sizeClasses[key] = `@media (min-width: ${val / 16}em)`;
});

/**
 * @namespace
 * @property {object}  colors                 - The default values for parties.
 * @property {object}  colors.green          - The default number of players.
 * @property {string}  colors.green.holly    - The default level for the party.
 * @property {object}  defaults.treasure      - The default treasure.
 * @property {number}  defaults.treasure.gold - How much gold the party starts with.
 */

const isMobile = (): boolean => {
	if (typeof window === 'undefined') {
		return true;
	} else {
		if (document.documentElement.clientWidth < breakpointSize.tablet) {
			return true;
		}
		return false;
	}
};
const isTablet = (): boolean => {
	if (typeof window === 'undefined') {
		return false;
	} else {
		if (
			document.documentElement.clientWidth >= breakpointSize.tablet &&
			document.documentElement.clientWidth < breakpointSize.desktop
		) {
			return true;
		}
		return false;
	}
};
const isDesktop = (): boolean => {
	if (typeof window === 'undefined') {
		return false;
	} else {
		if (document.documentElement.clientWidth >= breakpointSize.desktop) {
			return true;
		}
		return false;
	}
};
const isUltra = (): boolean => {
	if (typeof window === 'undefined') {
		return false;
	} else {
		if (document.documentElement.clientWidth > breakpointSize.ultra) {
			return true;
		}
		return false;
	}
};

export const theme = {
	colors: colors,
	MAX_WIDTH,
	uw: (size: number): string => {
		return Math.trunc((MAX_WIDTH / 100) * size) + 'px';
	},
	screenWidth: (): number => {
		if (typeof window === 'undefined') {
			return MAX_WIDTH;
		}
		return document.documentElement.clientWidth;
	},
	isMobile: isMobile,
	isTablet: isTablet,
	isDesktop: isDesktop,
	isUltra: isUltra,
	getPxWidth: (px: number): number => {
		if (typeof window !== 'undefined' && document.documentElement.clientWidth >= MAX_WIDTH) {
			return px;
		}
		if (isMobile()) {
			return Math.round((document.documentElement.clientWidth / calculationSize.mobile) * px);
		} else if (isTablet()) {
			return Math.round((document.documentElement.clientWidth / calculationSize.tablet) * px);
		} else if (isDesktop()) {
			return Math.round((document.documentElement.clientWidth / calculationSize.desktop) * px);
		}
		return px;
	},
	getVwWidth: (px: number): string => {
		if (typeof window !== 'undefined') {
			return px + 'px';
		}
		if (isMobile()) {
			return ((px / calculationSize.mobile) * 100).toFixed(VW_PRECISION) + 'vw';
		} else if (isTablet()) {
			return ((px / calculationSize.tablet) * 100).toFixed(VW_PRECISION) + 'vw';
		} else if (isDesktop()) {
			return ((px / calculationSize.desktop) * 100).toFixed(VW_PRECISION) + 'vw';
		}
		return px + 'px';
	},
	...sizeClasses,
	breakpointSize,
	calculationSize,
	pagePadding
};

const getUltraValue = (value: FormFactor): string | number => {
	const isUltraNumber = typeof value?.ultra === 'number';
	const isDesktopNumber = typeof value?.desktop === 'number';
	if (value?.ultra) {
		return isUltraNumber ? theme.uw(Number(value?.ultra)) : value?.ultra;
	}
	if (!value?.ultra && value?.desktop) {
		if (isDesktopNumber) {
			return theme.uw(Number(value?.desktop));
		}
		let safety = 0;
		let match = value?.desktop.match(/(-?\d+\.?(?:\d+)?vw)/);
		let newValue = value?.desktop;
		while (match && safety < 10) {
			const value = Number(match[1].replace('vw', ''));
			newValue = newValue.replace(match[1].trim(), theme.uw(value));
			match = newValue.match(/(-?\d+\.?(?:\d+)?vw)/);
			safety++;
		}
		return newValue;
	}
	return 'initial';
};

const _ResponsiveProperty = (prop: string, value: FormFactor): FlattenInterpolation<ThemeProps<DefaultTheme>> => css`
	${`${prop}: ${value?.mobile ? value.mobile : 'initial'};`}

	${(props): string => props.theme.tablet} {
		${`${prop}: ${value?.tablet ? value.tablet : 'initial'};`}
	}

	${(props): string => props.theme.desktop} {
		${`${prop}: ${value?.desktop ? value.desktop : 'initial'};`}
	}

	${(props): string => props.theme.ultra} {
		${`${prop}: ${getUltraValue(value)};`}
	}
`;

export const ResponsiveProperty = memoize(_ResponsiveProperty);

function getDeviceSize(deviceType: DeviceTypeEnum, forceDevice?: DeviceTypeEnum): number {
	if (forceDevice) {
		switch (forceDevice) {
			case DeviceTypeEnum.MOBILE:
				return calculationSize.mobile;
			case DeviceTypeEnum.TABLET:
				return calculationSize.mobile;
			case DeviceTypeEnum.DESKTOP:
			case DeviceTypeEnum.ULTRA:
				return calculationSize.desktop;
		}
	}
	switch (deviceType) {
		case DeviceTypeEnum.MOBILE:
			return calculationSize.mobile;
		case DeviceTypeEnum.TABLET:
			return calculationSize.mobile;
		case DeviceTypeEnum.DESKTOP:
		case DeviceTypeEnum.ULTRA:
			return calculationSize.desktop;
	}
}

function getDeviceValue(
	deviceType: DeviceTypeEnum,
	value: { mobile?: string; tablet?: string; desktop?: string },
	forceDevice?: DeviceTypeEnum
): string {
	if (forceDevice) {
		switch (forceDevice) {
			case DeviceTypeEnum.MOBILE:
				return value.mobile || 'initial';
			case DeviceTypeEnum.TABLET:
				return value.tablet || 'initial';
			case DeviceTypeEnum.DESKTOP:
			case DeviceTypeEnum.ULTRA:
				return value.desktop || 'initial';
		}
	}
	switch (deviceType) {
		case DeviceTypeEnum.MOBILE:
			return value.mobile || 'initial';
		case DeviceTypeEnum.TABLET:
			return value.tablet || 'initial';
		case DeviceTypeEnum.DESKTOP:
		case DeviceTypeEnum.ULTRA:
			return value.desktop || 'initial';
	}
}

function getForcedDevice(): DeviceTypeEnum | undefined {
	let forceDevice: DeviceTypeEnum | undefined;
	// @ts-ignore
	if (typeof window !== 'undefined' && window.forcedRenderingDevice) {
		// @ts-ignore
		forceDevice = window.forcedRenderingDevice;
	}
	return forceDevice;
}

function _ResponsivePXValue(
	prop: string,
	value: string | { mobile?: string; tablet?: string; desktop?: string }
): string {
	const forceDevice = getForcedDevice();
	const mobileSize = getDeviceSize(DeviceTypeEnum.MOBILE, forceDevice);
	const tabletSize = getDeviceSize(DeviceTypeEnum.TABLET, forceDevice);
	const desktopSize = getDeviceSize(DeviceTypeEnum.DESKTOP, forceDevice);
	if (typeof value === 'object') {
		let mobileValue = getDeviceValue(DeviceTypeEnum.MOBILE, value, forceDevice);
		let tabletValue = getDeviceValue(DeviceTypeEnum.TABLET, value, forceDevice);
		let desktopValue = getDeviceValue(DeviceTypeEnum.DESKTOP, value, forceDevice);
		const ultraValue = getDeviceValue(DeviceTypeEnum.ULTRA, value, forceDevice);
		let mobileMatch = mobileValue.match(/(-?\d+\.?(?:\d+)?px)/);
		let safety = 0;
		while (mobileMatch && safety < 10) {
			const value = Number(mobileMatch[1].replace('px', ''));
			mobileValue = mobileValue.replace(
				mobileMatch[1].trim(),
				((value / mobileSize) * 100).toFixed(VW_PRECISION) + 'vw'
			);
			mobileMatch = mobileValue.match(/(-?\d+\.?(?:\d+)?px)/);
			safety++;
		}
		let tabletMatch = tabletValue.match(/(-?\d+\.?(?:\d+)?px)/);
		safety = 0;
		while (tabletMatch && safety < 10) {
			const value = Number(tabletMatch[1].replace('px', ''));
			tabletValue = tabletValue.replace(
				tabletMatch[1].trim(),
				((value / tabletSize) * 100).toFixed(VW_PRECISION) + 'vw'
			);
			tabletMatch = tabletValue.match(/(-?\d+\.?(?:\d+)?px)/);
			safety++;
		}
		let desktopMatch = desktopValue.match(/(-?\d+\.?(?:\d+)?px)/);
		safety = 0;
		while (desktopMatch && safety < 10) {
			const value = Number(desktopMatch[1].replace('px', ''));
			desktopValue = desktopValue.replace(
				desktopMatch[1].trim(),
				((value / desktopSize) * 100).toFixed(VW_PRECISION) + 'vw'
			);
			desktopMatch = desktopValue.match(/(-?\d+\.?(?:\d+)?px)/);
			safety++;
		}
		return `
      ${`${prop}: ${mobileValue};`}

      ${theme.tablet} {
        ${`${prop}: ${tabletValue};`}
      }

      ${theme.desktop} {
        ${`${prop}: ${desktopValue};`}
      }

      ${theme.ultra} {
        ${`${prop}: ${ultraValue};`}
      }
    `;
	} else {
		let safety = 0;
		let ultraValue = value;
		let desktopValue = value;
		let tabletValue = value;
		let mobileValue = value;
		let match = desktopValue.match(/(-?\d+\.?(?:\d+)?px)/);
		while (match && safety < 10) {
			const value = Number(match[1].replace('px', ''));
			const vwValue = (value / theme.MAX_WIDTH) * 100;
			mobileValue = mobileValue.replace(
				match[1].trim(),
				((vwValue * MAX_WIDTH) / mobileSize).toFixed(VW_PRECISION) + 'vw'
			);
			tabletValue = tabletValue.replace(
				match[1].trim(),
				((vwValue * MAX_WIDTH) / tabletSize).toFixed(VW_PRECISION) + 'vw'
			);
			desktopValue = desktopValue.replace(
				match[1].trim(),
				((vwValue * MAX_WIDTH) / desktopSize).toFixed(VW_PRECISION) + 'vw'
			);
			ultraValue = ultraValue.replace(match[1].trim(), `${value}px`);
			match = desktopValue.match(/(-?\d+\.?(?:\d+)?px)/);
			safety++;
		}
		return `
      ${`${prop}: ${mobileValue};`}

      ${theme.tablet} {
        ${`${prop}: ${tabletValue};`}
      }

      ${theme.desktop} {
        ${`${prop}: ${desktopValue};`}
      }

      ${theme.ultra} {
        ${`${prop}: ${ultraValue};`}
      }
    `;
	}
}

export const ResponsivePXValue = memoize(_ResponsivePXValue, {
	cache: {
		create() {
			return {
				has(key) {
					const forceDevice = getForcedDevice() ?? '';
					const actualKey = forceDevice ? `${forceDevice}-${key}` : key;
					return actualKey in cahcedStyles;
				},
				get(key) {
					const forceDevice = getForcedDevice() ?? '';
					const actualKey = forceDevice ? `${forceDevice}-${key}` : key;
					return cahcedStyles[actualKey];
				},
				set(key, value) {
					const forceDevice = getForcedDevice() ?? '';
					const actualKey = forceDevice ? `${forceDevice}-${key}` : key;
					cahcedStyles[actualKey] = value;
				}
			};
		}
	}
});
