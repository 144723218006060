import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

// Register necessary components
ChartJS.register(ArcElement, Tooltip, Legend);


interface ActiveMembers {
  label: string;
  value: number;
}

interface PieChartComponentProps {
  data: {
    [key: number]: ActiveMembers; // Numeric keys mapping to UserScore objects
  };
}

const PieChart: React.FC<PieChartComponentProps> = (props) => {


  const rawData = Object.values(props.data);

  const labels: string[] = rawData.map(entry => entry.label);
  const values: number[] = rawData.map(entry => entry.value);


  // Sample data
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Bookings',
        data: values, // Example activity counts
        backgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#4BC0C0',
          '#9966FF',
          '#FF9F40',
          '#FF5733',
          '#33FF57',
          '#C70039',
          '#900C3F',
          '#581845'
        ],
        hoverOffset: 4,
      },
    ],
  };

  // Options for the chart
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Most Active Members',
      },
    },
  };

  return (
    <Pie data={data} options={options} />
  );
};

export default PieChart;
