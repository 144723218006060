/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * This enumeration defines the address group types
 */
export enum AddressGroupEnum {
	business = 'business',
	residential = 'residential'
}

export enum AggregationTypeEnum {
	IGNORE = 'IGNORE',
	MULTI_SELECT = 'MULTI_SELECT',
	SINGLE_SELECT = 'SINGLE_SELECT',
	SLIDER = 'SLIDER'
}

export enum CheckoutStepEnum {
	CONFIRM = 'CONFIRM',
	DELIVERY = 'DELIVERY',
	PAYMENT = 'PAYMENT'
}

export enum ConfirmStepEnum {
	CONFIRM = 'CONFIRM',
	PLACE_ORDER = 'PLACE_ORDER'
}

/**
 * The list of countries codes
 */
export enum CountryCodeEnum {
	AD = 'AD',
	AE = 'AE',
	AF = 'AF',
	AG = 'AG',
	AI = 'AI',
	AL = 'AL',
	AM = 'AM',
	AN = 'AN',
	AO = 'AO',
	AQ = 'AQ',
	AR = 'AR',
	AS = 'AS',
	AT = 'AT',
	AU = 'AU',
	AW = 'AW',
	AX = 'AX',
	AZ = 'AZ',
	BA = 'BA',
	BB = 'BB',
	BD = 'BD',
	BE = 'BE',
	BF = 'BF',
	BG = 'BG',
	BH = 'BH',
	BI = 'BI',
	BJ = 'BJ',
	BL = 'BL',
	BM = 'BM',
	BN = 'BN',
	BO = 'BO',
	BR = 'BR',
	BS = 'BS',
	BT = 'BT',
	BV = 'BV',
	BW = 'BW',
	BY = 'BY',
	BZ = 'BZ',
	CA = 'CA',
	CC = 'CC',
	CD = 'CD',
	CF = 'CF',
	CG = 'CG',
	CH = 'CH',
	CI = 'CI',
	CK = 'CK',
	CL = 'CL',
	CM = 'CM',
	CN = 'CN',
	CO = 'CO',
	CR = 'CR',
	CU = 'CU',
	CV = 'CV',
	CX = 'CX',
	CY = 'CY',
	CZ = 'CZ',
	DE = 'DE',
	DJ = 'DJ',
	DK = 'DK',
	DM = 'DM',
	DO = 'DO',
	DZ = 'DZ',
	EC = 'EC',
	EE = 'EE',
	EG = 'EG',
	EH = 'EH',
	ER = 'ER',
	ES = 'ES',
	ET = 'ET',
	FI = 'FI',
	FJ = 'FJ',
	FK = 'FK',
	FM = 'FM',
	FO = 'FO',
	FR = 'FR',
	GA = 'GA',
	GB = 'GB',
	GD = 'GD',
	GE = 'GE',
	GF = 'GF',
	GG = 'GG',
	GH = 'GH',
	GI = 'GI',
	GL = 'GL',
	GM = 'GM',
	GN = 'GN',
	GP = 'GP',
	GQ = 'GQ',
	GR = 'GR',
	GS = 'GS',
	GT = 'GT',
	GU = 'GU',
	GW = 'GW',
	GY = 'GY',
	HK = 'HK',
	HM = 'HM',
	HN = 'HN',
	HR = 'HR',
	HT = 'HT',
	HU = 'HU',
	ID = 'ID',
	IE = 'IE',
	IL = 'IL',
	IM = 'IM',
	IN = 'IN',
	IO = 'IO',
	IQ = 'IQ',
	IR = 'IR',
	IS = 'IS',
	IT = 'IT',
	JE = 'JE',
	JM = 'JM',
	JO = 'JO',
	JP = 'JP',
	KE = 'KE',
	KG = 'KG',
	KH = 'KH',
	KI = 'KI',
	KM = 'KM',
	KN = 'KN',
	KP = 'KP',
	KR = 'KR',
	KW = 'KW',
	KY = 'KY',
	KZ = 'KZ',
	LA = 'LA',
	LB = 'LB',
	LC = 'LC',
	LI = 'LI',
	LK = 'LK',
	LR = 'LR',
	LS = 'LS',
	LT = 'LT',
	LU = 'LU',
	LV = 'LV',
	LY = 'LY',
	MA = 'MA',
	MC = 'MC',
	MD = 'MD',
	ME = 'ME',
	MF = 'MF',
	MG = 'MG',
	MH = 'MH',
	MK = 'MK',
	ML = 'ML',
	MM = 'MM',
	MN = 'MN',
	MO = 'MO',
	MP = 'MP',
	MQ = 'MQ',
	MR = 'MR',
	MS = 'MS',
	MT = 'MT',
	MU = 'MU',
	MV = 'MV',
	MW = 'MW',
	MX = 'MX',
	MY = 'MY',
	MZ = 'MZ',
	NA = 'NA',
	NC = 'NC',
	NE = 'NE',
	NF = 'NF',
	NG = 'NG',
	NI = 'NI',
	NL = 'NL',
	NO = 'NO',
	NP = 'NP',
	NR = 'NR',
	NU = 'NU',
	NZ = 'NZ',
	OM = 'OM',
	PA = 'PA',
	PE = 'PE',
	PF = 'PF',
	PG = 'PG',
	PH = 'PH',
	PK = 'PK',
	PL = 'PL',
	PM = 'PM',
	PN = 'PN',
	PS = 'PS',
	PT = 'PT',
	PW = 'PW',
	PY = 'PY',
	QA = 'QA',
	RE = 'RE',
	RO = 'RO',
	RS = 'RS',
	RU = 'RU',
	RW = 'RW',
	SA = 'SA',
	SB = 'SB',
	SC = 'SC',
	SD = 'SD',
	SE = 'SE',
	SG = 'SG',
	SH = 'SH',
	SI = 'SI',
	SJ = 'SJ',
	SK = 'SK',
	SL = 'SL',
	SM = 'SM',
	SN = 'SN',
	SO = 'SO',
	SR = 'SR',
	ST = 'ST',
	SV = 'SV',
	SY = 'SY',
	SZ = 'SZ',
	TC = 'TC',
	TD = 'TD',
	TF = 'TF',
	TG = 'TG',
	TH = 'TH',
	TJ = 'TJ',
	TK = 'TK',
	TL = 'TL',
	TM = 'TM',
	TN = 'TN',
	TO = 'TO',
	TR = 'TR',
	TT = 'TT',
	TV = 'TV',
	TW = 'TW',
	TZ = 'TZ',
	UA = 'UA',
	UG = 'UG',
	UM = 'UM',
	US = 'US',
	UY = 'UY',
	UZ = 'UZ',
	VA = 'VA',
	VC = 'VC',
	VE = 'VE',
	VG = 'VG',
	VI = 'VI',
	VN = 'VN',
	VU = 'VU',
	WF = 'WF',
	WS = 'WS',
	YE = 'YE',
	YT = 'YT',
	ZA = 'ZA',
	ZM = 'ZM',
	ZW = 'ZW'
}

/**
 * The list of available currency codes
 */
export enum CurrencyEnum {
	AED = 'AED',
	AFN = 'AFN',
	ALL = 'ALL',
	AMD = 'AMD',
	ANG = 'ANG',
	AOA = 'AOA',
	ARS = 'ARS',
	AUD = 'AUD',
	AWG = 'AWG',
	AZM = 'AZM',
	AZN = 'AZN',
	BAM = 'BAM',
	BBD = 'BBD',
	BDT = 'BDT',
	BGN = 'BGN',
	BHD = 'BHD',
	BIF = 'BIF',
	BMD = 'BMD',
	BND = 'BND',
	BOB = 'BOB',
	BRL = 'BRL',
	BSD = 'BSD',
	BTN = 'BTN',
	BUK = 'BUK',
	BWP = 'BWP',
	BYN = 'BYN',
	BZD = 'BZD',
	CAD = 'CAD',
	CDF = 'CDF',
	CHE = 'CHE',
	CHF = 'CHF',
	CHW = 'CHW',
	CLP = 'CLP',
	CNY = 'CNY',
	COP = 'COP',
	CRC = 'CRC',
	CUP = 'CUP',
	CVE = 'CVE',
	CZK = 'CZK',
	DJF = 'DJF',
	DKK = 'DKK',
	DOP = 'DOP',
	DZD = 'DZD',
	EEK = 'EEK',
	EGP = 'EGP',
	ERN = 'ERN',
	ETB = 'ETB',
	EUR = 'EUR',
	FJD = 'FJD',
	FKP = 'FKP',
	GBP = 'GBP',
	GEK = 'GEK',
	GEL = 'GEL',
	GHS = 'GHS',
	GIP = 'GIP',
	GMD = 'GMD',
	GNF = 'GNF',
	GQE = 'GQE',
	GTQ = 'GTQ',
	GYD = 'GYD',
	HKD = 'HKD',
	HNL = 'HNL',
	HRK = 'HRK',
	HTG = 'HTG',
	HUF = 'HUF',
	IDR = 'IDR',
	ILS = 'ILS',
	INR = 'INR',
	IQD = 'IQD',
	IRR = 'IRR',
	ISK = 'ISK',
	JMD = 'JMD',
	JOD = 'JOD',
	JPY = 'JPY',
	KES = 'KES',
	KGS = 'KGS',
	KHR = 'KHR',
	KMF = 'KMF',
	KPW = 'KPW',
	KRW = 'KRW',
	KWD = 'KWD',
	KYD = 'KYD',
	KZT = 'KZT',
	LAK = 'LAK',
	LBP = 'LBP',
	LKR = 'LKR',
	LRD = 'LRD',
	LSL = 'LSL',
	LSM = 'LSM',
	LTL = 'LTL',
	LVL = 'LVL',
	LYD = 'LYD',
	MAD = 'MAD',
	MDL = 'MDL',
	MGA = 'MGA',
	MKD = 'MKD',
	MMK = 'MMK',
	MNT = 'MNT',
	MOP = 'MOP',
	MRO = 'MRO',
	MUR = 'MUR',
	MVR = 'MVR',
	MWK = 'MWK',
	MXN = 'MXN',
	MYR = 'MYR',
	MZN = 'MZN',
	NAD = 'NAD',
	NGN = 'NGN',
	NIC = 'NIC',
	NOK = 'NOK',
	NPR = 'NPR',
	NZD = 'NZD',
	OMR = 'OMR',
	PAB = 'PAB',
	PEN = 'PEN',
	PGK = 'PGK',
	PHP = 'PHP',
	PKR = 'PKR',
	PLN = 'PLN',
	PYG = 'PYG',
	QAR = 'QAR',
	RHD = 'RHD',
	ROL = 'ROL',
	RON = 'RON',
	RSD = 'RSD',
	RUB = 'RUB',
	RWF = 'RWF',
	SAR = 'SAR',
	SBD = 'SBD',
	SCR = 'SCR',
	SDG = 'SDG',
	SEK = 'SEK',
	SGD = 'SGD',
	SHP = 'SHP',
	SKK = 'SKK',
	SLL = 'SLL',
	SOS = 'SOS',
	SRD = 'SRD',
	STD = 'STD',
	SVC = 'SVC',
	SYP = 'SYP',
	SZL = 'SZL',
	THB = 'THB',
	TJS = 'TJS',
	TMM = 'TMM',
	TND = 'TND',
	TOP = 'TOP',
	TRL = 'TRL',
	TRY = 'TRY',
	TTD = 'TTD',
	TWD = 'TWD',
	TZS = 'TZS',
	UAH = 'UAH',
	UGX = 'UGX',
	USD = 'USD',
	UYU = 'UYU',
	UZS = 'UZS',
	VEB = 'VEB',
	VEF = 'VEF',
	VND = 'VND',
	VUV = 'VUV',
	WST = 'WST',
	XCD = 'XCD',
	XOF = 'XOF',
	XPF = 'XPF',
	YER = 'YER',
	YTL = 'YTL',
	ZAR = 'ZAR',
	ZMK = 'ZMK',
	ZWD = 'ZWD'
}

export enum CustomerTypeEnum {
	GUEST = 'GUEST',
	REGISTERED = 'REGISTERED'
}

export enum DeliveryStepEnum {
	DELIVERY_ADDRESS = 'DELIVERY_ADDRESS',
	DELIVERY_METHOD = 'DELIVERY_METHOD'
}

export enum DeviceTypeEnum {
	DESKTOP = 'DESKTOP',
	MOBILE = 'MOBILE',
	TABLET = 'TABLET',
	ULTRA = 'ULTRA'
}

export enum MarkupTypeEnum {
	FORMATTED_TEXT = 'FORMATTED_TEXT',
	HTML = 'HTML',
	MARKDOWN = 'MARKDOWN'
}

export enum OrderDirectionEnum {
	ASC = 'ASC',
	DESC = 'DESC'
}

export enum PageSectionObjectTypesEnum {
	PAGE_BANNER_SECTION = 'PAGE_BANNER_SECTION',
	PAGE_BLOCK_SECTION = 'PAGE_BLOCK_SECTION',
	PAGE_MARKUP_SECTION = 'PAGE_MARKUP_SECTION'
}

export enum PaymentMethodEnum {
	checkmo = 'checkmo',
	ozow = 'ozow',
	peachpayments_s2s = 'peachpayments_s2s'
}

export enum PaymentStepEnum {
	PAYMENT_DETAILS = 'PAYMENT_DETAILS',
	PAYMENT_METHOD = 'PAYMENT_METHOD',
	THREE_D_SECURE = 'THREE_D_SECURE'
}

export enum PaymentTokeStatus {
	SAVED = 'SAVED',
	TEMP_LOCAL = 'TEMP_LOCAL'
}

/**
 * The list of available payment token types
 */
export enum PaymentTokenTypeEnum {
	account = 'account',
	card = 'card'
}

export enum PeachPaymentsCreditCardTypeEnum {
	AMERICAN_EXPRESS = 'AMERICAN_EXPRESS',
	DINERS_CLUB = 'DINERS_CLUB',
	DISCOVER = 'DISCOVER',
	ELO = 'ELO',
	HIPER = 'HIPER',
	HIPERCARD = 'HIPERCARD',
	JCB = 'JCB',
	MAESTRO = 'MAESTRO',
	MASTERCARD = 'MASTERCARD',
	MIR = 'MIR',
	UNIONPAY = 'UNIONPAY',
	VISA = 'VISA'
}

/**
 * This enumeration defines the product attributes that are visible on the frontend
 */
export enum ProductAttributeEnum {
	ACCREDITATION = 'ACCREDITATION',
	AGE = 'AGE',
	BRAND = 'BRAND',
	COLOUR = 'COLOUR',
	CONCENTRATION = 'CONCENTRATION',
	DEPARTMENT = 'DEPARTMENT',
	DIET_VALUES = 'DIET_VALUES',
	ENVIRONMENTAL_VALUES = 'ENVIRONMENTAL_VALUES',
	FLAVOUR = 'FLAVOUR',
	FRAGRANCE = 'FRAGRANCE',
	GTIN = 'GTIN',
	HAIR_TYPE = 'HAIR_TYPE',
	INGREDIENTS = 'INGREDIENTS',
	INGREDIENT_VALUES = 'INGREDIENT_VALUES',
	LICENSING_STATUS = 'LICENSING_STATUS',
	LOCATION = 'LOCATION',
	MANUFACTURER = 'MANUFACTURER',
	NAME = 'NAME',
	ORGANIC_STATUS = 'ORGANIC_STATUS',
	OVERSTICKERS = 'OVERSTICKERS',
	PALM_OIL_STATUS = 'PALM_OIL_STATUS',
	QUANTITY = 'QUANTITY',
	RATING_FILTER = 'RATING_FILTER',
	SIZE = 'SIZE',
	SKIN_CONCERN = 'SKIN_CONCERN',
	SKIN_TYPE = 'SKIN_TYPE',
	SOCIAL_VALUES = 'SOCIAL_VALUES',
	SPECIAL_FROM_DATE = 'SPECIAL_FROM_DATE',
	SPECIAL_PRICE = 'SPECIAL_PRICE',
	SPECIAL_TO_DATE = 'SPECIAL_TO_DATE',
	VEGAN_STATUS = 'VEGAN_STATUS'
}

/**
 * This enumeration states whether a product stock status is in stock or out of stock
 */
export enum ProductStockStatus {
	IN_STOCK = 'IN_STOCK',
	OUT_OF_STOCK = 'OUT_OF_STOCK'
}

export enum ProductTypeEnum {
	AmGiftCardProduct = 'AmGiftCardProduct',
	BundleProduct = 'BundleProduct',
	ConfigurableProduct = 'ConfigurableProduct',
	DownloadableProduct = 'DownloadableProduct',
	GroupedProduct = 'GroupedProduct',
	ProductInterface = 'ProductInterface',
	SimpleProduct = 'SimpleProduct',
	VirtualProduct = 'VirtualProduct'
}

/**
 * This enumeration defines the review sorting order
 */
export enum ReviewSortOrderEnum {
	RECENT = 'RECENT',
	TOP = 'TOP'
}

/**
 * This enumeration defines the review vote types
 */
export enum ReviewVoteEnum {
	DOWN = 'DOWN',
	UP = 'UP'
}

/**
 * This enumeration indicates whether to return results in ascending or descending order
 */
export enum SortEnum {
	ASC = 'ASC',
	DESC = 'DESC'
}

/**
 * This enumeration defines the Single Sign-on (SSO) providers
 */
export enum SsoProviderEnum {
	APPLE = 'APPLE',
	FACEBOOK = 'FACEBOOK',
	GOOGLE = 'GOOGLE',
	INSTAGRAM = 'INSTAGRAM',
	LINKEDIN = 'LINKEDIN',
	TWITTER = 'TWITTER'
}

export enum SubscriptionStatusesEnum {
	NOT_ACTIVE = 'NOT_ACTIVE',
	SUBSCRIBED = 'SUBSCRIBED',
	UNCONFIRMED = 'UNCONFIRMED',
	UNSUBSCRIBED = 'UNSUBSCRIBED'
}

export interface ApplyCouponToCartInput {
	readonly cartId: string;
	readonly couponCode: string;
}

export interface CartAddressInput {
	readonly city: string;
	readonly company?: string | null;
	readonly countryCode: string;
	readonly firstname: string;
	readonly lastname: string;
	readonly postcode?: string | null;
	readonly region?: string | null;
	readonly regionId?: number | null;
	readonly saveInAddressBook?: boolean | null;
	readonly street: ReadonlyArray<string | null>;
	readonly telephone: string;
}

export interface CreateProductReviewInput {
	readonly nickname: string;
	readonly ratings: ReadonlyArray<ProductReviewRatingInput | null>;
	readonly sku: string;
	readonly summary: string;
	readonly text: string;
}

/**
 * Required fields for Payflow Pro and Payments Pro credit card payments
 */
export interface CreditCardDetailsInput {
	readonly ccExpMonth: number;
	readonly ccExpYear: number;
	readonly ccLast_4: number;
	readonly ccType: string;
}

export interface CustomerAddressAttributeInput {
	readonly attributeCode: string;
	readonly value: string;
}

export interface CustomerAddressInput {
	readonly addressGroup?: AddressGroupEnum | null;
	readonly city?: string | null;
	readonly company?: string | null;
	readonly countryCode?: CountryCodeEnum | null;
	readonly countryId?: CountryCodeEnum | null;
	readonly customAttributes?: ReadonlyArray<CustomerAddressAttributeInput | null> | null;
	readonly defaultBilling?: boolean | null;
	readonly defaultShipping?: boolean | null;
	readonly deliveryNote?: string | null;
	readonly fax?: string | null;
	readonly firstname?: string | null;
	readonly lastname?: string | null;
	readonly middlename?: string | null;
	readonly postcode?: string | null;
	readonly prefix?: string | null;
	readonly region?: CustomerAddressRegionInput | null;
	readonly street?: ReadonlyArray<string | null> | null;
	readonly suburb?: string | null;
	readonly suffix?: string | null;
	readonly telephone?: string | null;
	readonly telephoneAlt?: string | null;
	readonly vatId?: string | null;
}

/**
 * CustomerAddressRegionInput defines the customer's state or province
 */
export interface CustomerAddressRegionInput {
	readonly region?: string | null;
	readonly regionCode?: string | null;
	readonly regionId?: number | null;
}

export interface CustomerCreateInput {
	readonly allowRemoteShoppingAssistance?: boolean | null;
	readonly dateOfBirth?: string | null;
	readonly dob?: string | null;
	readonly email: string;
	readonly firstname: string;
	readonly gender?: number | null;
	readonly isSubscribed?: boolean | null;
	readonly lastname: string;
	readonly middlename?: string | null;
	readonly mobile?: string | null;
	readonly password?: string | null;
	readonly prefix?: string | null;
	readonly suffix?: string | null;
	readonly taxvat?: string | null;
}

export interface CustomerInput {
	readonly dateOfBirth?: string | null;
	readonly dob?: string | null;
	readonly email?: string | null;
	readonly firstname?: string | null;
	readonly gender?: number | null;
	readonly isSubscribed?: boolean | null;
	readonly lastname?: string | null;
	readonly middlename?: string | null;
	readonly mobile?: string | null;
	readonly password?: string | null;
	readonly prefix?: string | null;
	readonly suffix?: string | null;
	readonly taxvat?: string | null;
}

export interface DateTimeEqualsInRangeFilterInput {
	readonly in?: ReadonlyArray<any | null> | null;
	readonly notIn?: ReadonlyArray<any | null> | null;
	readonly equals?: any | null;
	readonly doesNotEqual?: any | null;
	readonly greaterThan?: any | null;
	readonly lessThan?: any | null;
}

/**
 * Defines a filter that matches the input exactly.
 */
export interface FilterEqualTypeInput {
	readonly eq?: string | null;
	readonly in?: ReadonlyArray<string | null> | null;
}

/**
 * Defines a filter that performs a fuzzy search.
 */
export interface FilterMatchTypeInput {
	readonly match?: string | null;
}

/**
 * Defines a filter that matches a range of values, such as prices or dates.
 */
export interface FilterRangeTypeInput {
	readonly from?: string | null;
	readonly to?: string | null;
}

/**
 * A set of relative URLs that PayPal will use in response to various actions during the authorization process. Magento prepends the base URL to this value to create a full URL. For example, if the full URL is https: // www.example.com/path/to/page.html, the relative URL is path/to/page.html. Use this input for Payments Pro Hosted Solution payment method.
 */
export interface HostedProInput {
	readonly cancelUrl: string;
	readonly returnUrl: string;
}

export interface IDEqualsInFilterInput {
	readonly in?: ReadonlyArray<string | null> | null;
	readonly notIn?: ReadonlyArray<string | null> | null;
	readonly equals?: string | null;
	readonly doesNotEqual?: string | null;
}

/**
 * A set of relative URLs that Ozow will use in response to various actions during the authorization process.
 */
export interface OzowInput {
	readonly cancelUrl: string;
	readonly successUrl: string;
}

export interface OzowRedirectInfoInput {
	readonly orderNumber: string;
}

export interface PageSectionFilterInput {
	readonly search?: string | null;
	readonly id?: IDEqualsInFilterInput | null;
	readonly createdAt?: DateTimeEqualsInRangeFilterInput | null;
	readonly updatedAt?: DateTimeEqualsInRangeFilterInput | null;
	readonly objectType?: PageSectionObjectTypesEnumEqualsInFilterInput | null;
}

export interface PageSectionObjectTypesEnumEqualsInFilterInput {
	readonly in?: ReadonlyArray<PageSectionObjectTypesEnum | null> | null;
	readonly notIn?: ReadonlyArray<PageSectionObjectTypesEnum | null> | null;
	readonly equals?: PageSectionObjectTypesEnum | null;
	readonly doesNotEqual?: PageSectionObjectTypesEnum | null;
}

export interface PageSectionOrderInput {
	readonly id?: OrderDirectionEnum | null;
	readonly createdAt?: OrderDirectionEnum | null;
	readonly updatedAt?: OrderDirectionEnum | null;
	readonly name?: OrderDirectionEnum | null;
	readonly objectType?: OrderDirectionEnum | null;
}

/**
 * Required input for Payflow Express Checkout payments
 */
export interface PayflowExpressInput {
	readonly payerId: string;
	readonly token: string;
}

/**
 * A set of relative URLs that PayPal will use in response to various actions during the authorization process. Magento prepends the base URL to this value to create a full URL. For example, if the full URL is https: // www.example.com/path/to/page.html, the relative URL is path/to/page.html. Use this input for Payflow Link and Payments Advanced payment methods.
 */
export interface PayflowLinkInput {
	readonly cancelUrl: string;
	readonly errorUrl: string;
	readonly returnUrl: string;
}

/**
 * Required input for Payflow Pro and Payments Pro payment methods.
 */
export interface PayflowProInput {
	readonly ccDetails: CreditCardDetailsInput;
	readonly isActivePaymentTokenEnabler?: boolean | null;
}

export interface PaymentMethodInput {
	readonly code: string;
	readonly hostedPro?: HostedProInput | null;
	readonly ozow?: OzowInput | null;
	readonly payflowExpress?: PayflowExpressInput | null;
	readonly payflowLink?: PayflowLinkInput | null;
	readonly payflowpro?: PayflowProInput | null;
	readonly payflowproCcVault?: VaultTokenInput | null;
	readonly paypalExpress?: PaypalExpressInput | null;
	readonly peachpaymentsS2s?: PeachPaymentsCardInput | null;
	readonly purchaseOrderNumber?: string | null;
}

/**
 * Required input for Express Checkout and Payments Standard payments
 */
export interface PaypalExpressInput {
	readonly payerId: string;
	readonly token: string;
}

/**
 * PeachPayments3DSCustomerInput is the input required to validate a 3ds request into peach payments
 */
export interface PeachPayments3DsBrowserInput {
	readonly acceptHeader?: string | null;
	readonly challengeWindow?: string | null;
	readonly javaEnabled?: boolean | null;
	readonly language?: string | null;
	readonly screenColorDepth?: string | null;
	readonly screenHeight?: string | null;
	readonly screenWidth?: string | null;
	readonly timezone?: string | null;
	readonly userAgent?: string | null;
}

/**
 * PeachPaymentsCardInput & Cart id
 */
export interface PeachPayments3DsInput {
	readonly browser: PeachPayments3DsBrowserInput;
	readonly card: PeachPaymentsCardInput;
	readonly cartId: string;
	readonly redirectTo?: string | null;
}

/**
 * PeachPaymentsCardInput is the input required to add a card into peach payments
 */
export interface PeachPaymentsCardInput {
	readonly brand?: PeachPaymentsCreditCardTypeEnum | null;
	readonly cvv?: string | null;
	readonly expiryMonth?: string | null;
	readonly expiryYear?: string | null;
	readonly name?: string | null;
	readonly nickname?: string | null;
	readonly number?: string | null;
	readonly saveCard?: boolean | null;
	readonly threeDSecure?: ThreeDSecureInput | null;
	readonly token?: string | null;
}

export interface PlaceOrderInput {
	readonly cartId: string;
}

/**
 * ProductAttributeFilterInput defines the filters to be used in the search. A filter contains at least one attribute, a comparison operator, and the value that is being searched for.
 */
export interface ProductAttributeFilterInput {
	readonly accreditation?: FilterEqualTypeInput | null;
	readonly age?: FilterEqualTypeInput | null;
	readonly brand?: FilterEqualTypeInput | null;
	readonly categoryId?: FilterEqualTypeInput | null;
	readonly categoryUid?: FilterEqualTypeInput | null;
	readonly colour?: FilterEqualTypeInput | null;
	readonly concentration?: FilterEqualTypeInput | null;
	readonly department?: FilterEqualTypeInput | null;
	readonly description?: FilterMatchTypeInput | null;
	readonly dietValues?: FilterEqualTypeInput | null;
	readonly environmentalValues?: FilterEqualTypeInput | null;
	readonly flavour?: FilterEqualTypeInput | null;
	readonly fragrance?: FilterEqualTypeInput | null;
	readonly gtin?: FilterMatchTypeInput | null;
	readonly hairType?: FilterEqualTypeInput | null;
	readonly ingredientValues?: FilterEqualTypeInput | null;
	readonly ingredients?: FilterEqualTypeInput | null;
	readonly licensingStatus?: FilterEqualTypeInput | null;
	readonly location?: FilterEqualTypeInput | null;
	readonly manufacturer?: FilterMatchTypeInput | null;
	readonly name?: FilterMatchTypeInput | null;
	readonly organicStatus?: FilterEqualTypeInput | null;
	readonly palmOilStatus?: FilterEqualTypeInput | null;
	readonly price?: FilterRangeTypeInput | null;
	readonly quantity?: FilterEqualTypeInput | null;
	readonly ratingFilter?: FilterMatchTypeInput | null;
	readonly shortDescription?: FilterMatchTypeInput | null;
	readonly size?: FilterEqualTypeInput | null;
	readonly skinConcern?: FilterEqualTypeInput | null;
	readonly skinType?: FilterEqualTypeInput | null;
	readonly sku?: FilterEqualTypeInput | null;
	readonly socialValues?: FilterEqualTypeInput | null;
	readonly urlKey?: FilterEqualTypeInput | null;
	readonly veganStatus?: FilterEqualTypeInput | null;
	readonly categorySlug?: FilterEqualTypeInput | null;
	readonly brandId?: FilterEqualTypeInput | null;
}

/**
 * ProductAttributeSortInput specifies the attribute to use for sorting search results and indicates whether the results are sorted in ascending or descending order. It's possible to sort products using searchable attributes with enabled 'Use in Filter Options' option
 */
export interface ProductAttributeSortInput {
	readonly name?: SortEnum | null;
	readonly position?: SortEnum | null;
	readonly price?: SortEnum | null;
	readonly relevance?: SortEnum | null;
}

export interface ProductReviewRatingInput {
	readonly id: string;
	readonly valueId: string;
}

export interface RemoveCouponFromCartInput {
	readonly cartId: string;
}

/**
 * Review sorting input
 */
export interface ReviewSortInput {
	readonly direction: SortEnum;
	readonly order: ReviewSortOrderEnum;
}

/**
 * Review vote input
 */
export interface ReviewVoteInput {
	readonly reviewId: number;
	readonly vote: ReviewVoteEnum;
}

export interface SearchFiltersInput {
	readonly query: string;
}

export interface SetPaymentMethodOnCartInput {
	readonly cartId: string;
	readonly paymentMethod: PaymentMethodInput;
}

export interface SetShippingAddressesOnCartInput {
	readonly cartId: string;
	readonly shippingAddresses: ReadonlyArray<ShippingAddressInput | null>;
}

export interface SetShippingMethodsOnCartInput {
	readonly cartId: string;
	readonly shippingMethods: ReadonlyArray<ShippingMethodInput | null>;
}

export interface ShippingAddressInput {
	readonly address?: CartAddressInput | null;
	readonly customerAddressId?: number | null;
	readonly customerNotes?: string | null;
	readonly pickupLocationCode?: string | null;
}

export interface ShippingMethodInput {
	readonly carrierCode: string;
	readonly methodCode: string;
}

/**
 * ThreeDSecure details from the 3ds iframe returned data
 */
export interface ThreeDSecureInput {
	readonly acsTransactionId?: string | null;
	readonly dsTransactionId?: string | null;
	readonly eci?: string | null;
	readonly paRes?: string | null;
	readonly verificationId?: string | null;
	readonly xid?: string | null;
}

/**
 * Required input for payment methods with Vault support.
 */
export interface VaultTokenInput {
	readonly publicHash: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
