import React from 'react';
import styled from 'styled-components';
import { ResponsivePXValue } from '../Theme';
import Button from '../atoms/Button';
import Title from '../atoms/Title';

const Modal = styled.div<{ display: string }>`
	position: fixed;
	z-index: 2;
	display: ${(props): string => (props.display === 'true' ? 'flex' : 'none')};
	${ResponsivePXValue('width', { mobile: '100vw', tablet: '500px', desktop: '100vw' })}
	${ResponsivePXValue('height', { mobile: '100%', tablet: '100%', desktop: '100%' })}
	background: rgba(0,0,0,0.5);
	justify-content: flex-end;
	${ResponsivePXValue('right', { mobile: '0', tablet: '0', desktop: '0' })}
	${ResponsivePXValue('top', { mobile: '0', tablet: '0', desktop: '0' })}
	

	animation: ${(props): string => (props.display === 'true' ? 'fadeInDesktop2 1s' : 'fadeOutDesktop2 1s')};
	@keyframes fadeInDesktop2 {
		0% { background: rgba(0,0,0,0); }
		100% { background: rgba(0,0,0,0.5); }
	}
	@keyframes fadeOutDesktop2 {
		0% { background: rgba(0,0,0,0); }
		99% { background: rgba(0,0,0,0.5); }
	}
`

const ModalTop = styled.div``
const ModalBottom = styled.div``
const ModalInner = styled.div<{ display: string }>`
	background: #fff;
	position: fixed;
	box-shadow: -2px 6px 7px #00000061;
	justify-content: space-between;
    flex-direction: column;
	${ResponsivePXValue('right', { mobile: '0', tablet: '0', desktop: '0' })}
	${ResponsivePXValue('top', { mobile: '0', tablet: '0', desktop: '0' })}
	${ResponsivePXValue('height', { mobile: '100%', tablet: '100%', desktop: '100%' })}
	${ResponsivePXValue('width', { mobile: '80vw', tablet: '', desktop: '30vw' })}
	display: ${(props): string => (props.display === 'true' ? 'flex' : 'none')};
	animation: ${(props): string => (props.display === 'true' ? 'fadeInDesktop 1s' : 'fadeOutDesktop 1s')};



	
	@keyframes fadeInDesktop {
		0% { right: -500px; }
		100% { right: 0; }
	}
	@keyframes fadeOutDesktop {
		100% { right: 0; }
		0% { right: -500px; }
	}
	.title {
		${ResponsivePXValue('padding', { mobile: '20px', tablet: '26px', desktop: '26px' })}
    	background-color: #009bff;
		width: 100%!important;
	}
`

const ModalBody = styled.div`
	${ResponsivePXValue('padding', { mobile: '10px', tablet: '36px', desktop: '36px' })}
	${ResponsivePXValue('gap', { mobile: '10px', tablet: '20px', desktop: '20px' })}
	display: flex;
	height: 100%;
    flex-direction: column;
`

const ModalButtons = styled.div`
	display: flex;
    gap: 10px;
	${ResponsivePXValue('padding', { mobile: '20px', tablet: '35px', desktop: '35px' })}
`

interface HeaderNavigationProps {
	title?: string;
	formId?: string;
	display: boolean;
	handleClose?: () => void;
	handleSubmit?: () => void;
	children: React.ReactNode | string | any;
}

const HeaderNavigation: React.FC<HeaderNavigationProps> = (props) => {

	const _handleClose = () => {
		if (props.handleClose) {
			props.handleClose()
		}
	}

	const _handleSubmit = () => {
		if (props.handleSubmit) {
			props.handleSubmit()
		}
	}

	return (
		<Modal display={props.display.toString()} >
			<ModalInner display={props.display.toString()}>
				<ModalTop>
					<Title color="#fff" variant='t2' className='title' >{props.title}</Title>
					<ModalBody>
						{props.children}
					</ModalBody>
				</ModalTop>
				<ModalBottom>
					<ModalButtons>
						<Button colors={{ color: '#000', backgroundColor: '#ccc' }} title={'cancel'} onClick={() => _handleClose()} />
						{props.formId ? (
							<Button type='submit' colors={{ color: '#fff', backgroundColor: '#009bff' }} title={'Submit'} formId={props.formId} />
						) : 
						props.handleSubmit ? (
							<Button colors={{ color: '#fff', backgroundColor: '#009bff' }} title={'Submit'} onClick={() => _handleSubmit()} />
						) : ('')}
					</ModalButtons>
				</ModalBottom>
			</ModalInner>
		</Modal>
	);
};

export default HeaderNavigation;
