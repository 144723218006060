import React from 'react';
import styled from 'styled-components';
import { ResponsivePXValue } from '../Theme';

const Wrapper = styled.div`
	${ResponsivePXValue('font-size', { mobile: '13px', tablet: '20px', desktop: '20px' })}
	&.error {
		color: red;
	}
	&.success {
		color: green;
	}
`;

interface MessagesProps {
	message: {
		[key: string]: any;
	}
}
const Messages: React.FC<MessagesProps> = (props) => {

	const { message } = props;

	return (
		<Wrapper className={message.type}>
			{message.message}
		</Wrapper>
	);
};

export default Messages;
