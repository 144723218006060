import React from 'react';
import styled, { useTheme } from 'styled-components';
import { ResponsivePXValue } from '../Theme';
import Title from '../atoms/Title';
import { Link, Navigate } from 'react-router-dom';
import Paragraph from '../atoms/Paragraph';
import HeaderNavigation from './HeaderNavigation';
import { useAuth } from '../../services/AuthProvider';

const NavBar = styled.div`
	top: 0;
	left: 0;
	width: 100vw;
	background-color: #009bff;
	position: fixed;
	box-shadow: 0px 0px 7px #ccc;

`

const NavBarInner = styled.div`
	display: flex;
	justify-content: space-between;
    align-items: center;

	${ResponsivePXValue('padding', { mobile: '10px 15px', tablet: '20px 30px', desktop: '20px 30px' })}

	h1 {
		${ResponsivePXValue('width', { mobile: '90px', tablet: '', desktop: '' })}

	}
`

const Nav = styled.div`
	display: flex;
    align-items: end;
    justify-content: space-between;
	${ResponsivePXValue('gap', { mobile: '15px', tablet: '30px', desktop: '30px' })}
	a {
		color: #FFF;
		font-family: monospace;
		font-weight: 700;
		text-align: center;
		text-decoration: none;
		text-transform: none;
		margin: 0;
		${ResponsivePXValue('font-size', { mobile: '3vw', tablet: '28px', desktop: '20px' })}
	}
`
const Munuitem = styled.div`
		color: #FFF;
		font-family: monospace;
		font-weight: 700;
		text-align: center;
		text-decoration: none;
		text-transform: none;
		margin: 0;
		${ResponsivePXValue('font-size', { mobile: '3vw', tablet: '28px', desktop: '20px' })}
`

const NavRight = styled.div`
display: flex;
	align-items: center;
	${ResponsivePXValue('gap', { mobile: '30px', tablet: '30px', desktop: '30px' })}
`

const NavLeft = styled.div`
	display: flex;
	align-items: center;
	${ResponsivePXValue('gap', { mobile: '30px', tablet: '30px', desktop: '30px' })}

`

interface AdminHeaderProps {
}

const AdminHeader: React.FC<AdminHeaderProps> = (props) => {

	const theme = useTheme();
	const { user, signOut } = useAuth();

	const _logout = () => {
		signOut()
		return <Navigate to="/" replace />;
	}

	return (
		<NavBar>
			<NavBarInner>
				<NavLeft>
					<Title variant='t4' align='left' color={theme.colors.white.pureWhite}>MALMESBURY SQUASH</Title>
					
				</NavLeft>
				<NavRight>
					{/* {user?.first_name ?
						<Paragraph>Hi, {user?.first_name} {user?.last_name}</Paragraph>
						: ('')
					} */}
					<Nav>
						<Link to={'/admin'}>Dashboard</Link>
						<Link to={'/admin/members'}>Members</Link>
						<Link to={'/admin/kampioenskappe'}>Kampioenskappe</Link>
					</Nav>
					<HeaderNavigation />
				</NavRight>
			</NavBarInner>
		</NavBar >
	);
};

export default AdminHeader;
