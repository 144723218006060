import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ResponsivePXValue } from '../Theme';
import Header from '../organisms/Header';
import Title from '../atoms/Title';
import update from 'react-addons-update';
import Button from '../atoms/Button';

const Wrapper = styled.div`
	width: 100vw;
	height: 100vh;

	background-image: url(squashbg.jpg);
	${ResponsivePXValue('background-size', { mobile: 'cover', tablet: 'cover', desktop: 'cover' })}
	${ResponsivePXValue('background-position-x', { mobile: '95%', tablet: '95%', desktop: '50%' })}
	${ResponsivePXValue('background-position-y', { mobile: '90%', tablet: '95%', desktop: '90%' })}
	background-attachment: local;
	background-repeat: no-repeat;
`


const WrapperInner = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	${ResponsivePXValue('margin-top', { mobile: '0', tablet: '0', desktop: '0' })}

`

const ImageContainer = styled.div`
	background-image: url(maps.png);
	${ResponsivePXValue('width', { mobile: '90vw', tablet: '500px', desktop: '500px' })}
	${ResponsivePXValue('height', { mobile: '200px', tablet: '500px', desktop: '500px' })}
	${ResponsivePXValue('background-size', { mobile: 'cover', tablet: 'cover', desktop: 'cover' })}
	${ResponsivePXValue('background-position-x', { mobile: '50%', tablet: '50%', desktop: '50%' })}
	${ResponsivePXValue('background-position-y', { mobile: '50%', tablet: '50%', desktop: '50%' })}
	background-attachment: local;
	background-repeat: no-repeat;
`
const Body = styled.div`
	display: flex;
	width: 100vw;
	${ResponsivePXValue('align-items', { mobile: 'center', tablet: 'flex-start', desktop: 'flex-start' })}
	justify-content: space-between;
	background: rgb(255,255,255);
	${ResponsivePXValue('background', { mobile: '', tablet: 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.836594012605042) 50%, rgba(255,255,255,1) 75%, rgba(255,255,255,1) 100%)', desktop: 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 25%, rgba(255,255,255,0.836594012605042) 50%, rgba(255,255,255,1) 75%, rgba(255,255,255,1) 100%)' })}
	${ResponsivePXValue('padding', { mobile: '', tablet: '50px', desktop: '50px' })}
	${ResponsivePXValue('flex-direction', { mobile: 'column', tablet: 'row', desktop: 'row' })}
	${ResponsivePXValue('gap', { mobile: '15px', tablet: '0', desktop: '0' })}

	`
const BodyLeft = styled.div`
	display: flex;
	flex-direction: column;
	gap:15px;
	${ResponsivePXValue('width', { mobile: '90vw', tablet: '500px', desktop: '500px' })}
`
const BodyRight = styled.div`
	display: flex;
	flex-direction: column;
	gap:15px;
	h1 {
		${ResponsivePXValue('text-align', { mobile: 'left', tablet: 'right', desktop: 'right' })}

	}
`
const Input = styled.input`
	padding: 15px;
	font-size: 20px;
    width: -webkit-fill-available;
`

interface HomePageProps {
	name: string;
	contact: string;
	email: string;
}

const DEFAULT_STATE: HomePageProps = {
	name: '',
	contact: '',
	email: ''
};

const HomePage: React.FC = () => {

	const [state, setState] = useState<HomePageProps>({ ...DEFAULT_STATE });

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setState((prevState) =>
			update(prevState, {
				[name]: { $set: value }
			})
		);
	};

	return (<Wrapper>
		<Header />
		<WrapperInner>
			<Body>
				<BodyLeft>
				<Title color="#000" variant='t1' >Join Us</Title>
				<Input type='text' name="name" placeholder='Full name' value={state.name} onChange={handleChange} minLength={4} maxLength={4} />
				<Input type='text' name="contact" placeholder='Contact' value={state.contact} onChange={handleChange} minLength={4} maxLength={4} />
				<Input type='text' name="email" placeholder='Email' value={state.email} onChange={handleChange} minLength={4} maxLength={4} />
				<Button colors={{ color: '#fff', backgroundColor: '#009bff' }} title={'Submit'} />
				</BodyLeft>
				<BodyRight>
					<Title color="#000" variant='t1' align='right' >Find Us</Title>
					<ImageContainer></ImageContainer>
				</BodyRight>
			</Body>
		</WrapperInner>
	</Wrapper>);
};

export default HomePage;
