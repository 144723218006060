import React from 'react';

import styled, { css } from 'styled-components';

import { ResponsivePXValue } from '../Theme';

export type ParagraphVariant = 'p1' | 'p2';
export type ParagraphAlignment = 'left' | 'center' | 'right' | 'justify';
export type ParagraphTransforms = 'none' | 'capitalize' | 'uppercase' | 'lowercase';
export type ParagraphDecorations = 'none' | 'underline' | 'overline' | 'line-through' | 'italic';

const P = styled.p<{
	align: string;
	decoration: string;
	transform: string;
	color?: string;
	bold: string;
	variant: string;
	display: string;
}>`
	color: ${(props): string => props.color || '#fff'};
	text-align: ${(props): string => props.align};
	text-decoration: ${(props): string => props.decoration};
	display: ${(props): string => props.display};
	text-transform: ${(props): string => props.transform};
	font-weight: ${(props): string => (props.bold === 'true' ? '700' : '400')};
	margin: 0;

	${(props) =>
		props.variant === 'p1' &&
		css`
			font-family: monospace;
			${ResponsivePXValue('font-size', { mobile: '3vw', tablet: '30px', desktop: '20px' })}
		`}

	${(props) =>
		props.variant === 'p2' &&
		css`
			font-family: monospace;
			${ResponsivePXValue('font-size', { mobile: '10px', tablet: '20px', desktop: '20px' })}
		`}

  &::first-letter {
		text-transform: uppercase;
	}
`;
export interface ParagraphProps {
	children: React.ReactNode;
	variant?: ParagraphVariant;
	align?: ParagraphAlignment;
	transform?: ParagraphTransforms;
	decoration?: ParagraphDecorations;
	display?: string;
	color?: string;
	skeleton?: boolean;
	className?: string;
	bold?: string;
	onClick?: () => void;
}

const Paragraph: React.FC<ParagraphProps> = (props) => {
	const {
		children,
		variant = 'p1',
		align = 'left',
		transform = 'none',
		decoration = 'none',
		color,
		className,
		onClick,
		bold = 'false',
		display = 'block'
	} = props;

	return (
		<P
			onClick={onClick}
			align={align}
			display={display}
			variant={variant}
			decoration={decoration}
			transform={transform}
			color={color}
			className={className}
			bold={bold}
		>
			{children}
		</P>
	);
};

export default Paragraph;