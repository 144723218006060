import React, { ChangeEvent, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Link } from 'react-router-dom';
import { ResponsivePXValue } from '../Theme';
import update from 'react-addons-update';
import Modal from './Modal';
import axios from 'axios';
import { useAuth } from '../../services/AuthProvider';
import Title from '../atoms/Title';
import Button from '../atoms/Button';
import Paragraph from '../atoms/Paragraph';


const NavBar = styled.div`
	display: flex;
	justtify-content: center;
	align-items: center;
	svg {
		cursor: pointer; 
	}

`
const AdminNav = styled.div`
	display: flex;
	align-items: end;
	flex-direction: column;
	${ResponsivePXValue('gap', { mobile: '20px', tablet: '20px', desktop: '30px' })}
	
`

const Munuitem = styled.div`
	color: #000;
	font-family: monospace;
	font-weight: 700;
	margin: 0;
	font-weight: 700;
	cursor: pointer;
	${ResponsivePXValue('font-size', { mobile: '15px', tablet: '25px', desktop: '25px' })}
	a {
	color: #000 !important;
	${ResponsivePXValue('font-size', { mobile: '15px!important', tablet: '25px!important', desktop: '25px!important' })}
	}
`
const Form = styled.form`
	display: flex;
	gap: 10px;
	flex-direction: column;
	input {
		border: 1px solid #ccc;
		padding: 10px 10px;
		border-radius: 5px;
	}
	button {
		${ResponsivePXValue('font-size', { mobile: '13px', tablet: '20px', desktop: '20px' })}
		${ResponsivePXValue('padding', { mobile: '2px', tablet: '3px', desktop: '3px' })}
		${ResponsivePXValue('width', { mobile: '100% ', tablet: '200px', desktop: '100%' })}
		background-color: #000;
		cursor: pointer;
		color: #fff;
		border-width: 2px;
		border-style: solid;
	}

`
const ModalInner = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
`
const Top = styled.div`
`
const Bottom = styled.div`
`

const Event = styled.div`
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
	${ResponsivePXValue('padding', { mobile: '10px 0', tablet: '3px10px 0', desktop: '10px 0' })}

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 10px;
	.smalltitle {
		width: 100%;
	}
`


const InfoBlock = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`




interface HeaderNavigationProps {
	date?: Date;
	setDate?: (date: Date) => void;
}

interface BookingsPageProps {
	menuModal: boolean;
	memberModal: boolean;
	entries: string[];

}

const DEFAULT_STATE: BookingsPageProps = {
	menuModal: false,
	memberModal: false,
	entries: [],

};
type Member = {
	contact: string;
	first_name: string;
	last_name: string;
	email: string;
};

const HeaderNavigation: React.FC<HeaderNavigationProps> = (props) => {

	const [state, setState] = useState<BookingsPageProps>({ ...DEFAULT_STATE });
	const theme = useTheme();
	const { user, signOut, updateUserSession } = useAuth();
	const [formData, setFormData] = useState<Member>({
		first_name: '',
		last_name: '',
		email: '',
		contact: ''
	});

	const showMenu = () => {
		setState((prevState) =>
			update(prevState, {
				menuModal: { $set: !state.menuModal }
			})
		);
	}

	const showProfile = () => {
		setState((prevState) =>
			update(prevState, {
				menuModal: { $set: false },
				memberModal: { $set: !state.memberModal }
			})
		);
	}

	const _logout = () => {
		signOut()
		setState((prevState) =>
			update(prevState, {
				menuModal: { $set: !state.menuModal }
			})
		);
	}

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value
		} = e.target;

		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleSubmit = () => {
		axios.post(process.env.REACT_APP_API_URL + 'members/' + user?.id, formData)
			.then(response => {
				if (user) {
					user.first_name = formData.first_name
					user.last_name = formData.last_name
					user.email = formData.email
					user.contact = formData.contact
					updateUserSession(user)
					showProfile();
				}
			})
			.catch(error => {
				console.log('error', error)
			});
	};

	const _handleEnter = () => {
		console.log('_handleEnter')

		axios.post(process.env.REACT_APP_API_URL + 'kampioenskappe/inskrywing',
			{
				member_id: user?.member_number,
			})
			.then(response => {
				let rawEntries = state.entries;
				if (user) {
					rawEntries.push(user.member_number,)
					setState((prevState) =>
						update(prevState, {
							entries: { $set: rawEntries },
						})
					);
				}
			})
			.catch(error => {

			});
	};

	useEffect(() => {
		axios.get(process.env.REACT_APP_API_URL + 'kampioenskappe').then((response: any) => {
			let rawEntries: string[] = [];
			for (let i = 0; i < response.data.length; i++) {
				rawEntries.push(response.data[i].member_number)
			}
			setState((prevState) =>
				update(prevState, {
					entries: { $set: rawEntries },
				})
			);
		});


		setFormData({
			first_name: user?.first_name || '',
			last_name: user?.last_name || '',
			email: user?.email || '',
			contact: user?.contact || ''
		});
	}, [user]);

	return (
		<NavBar>
			<svg xmlns="http://www.w3.org/2000/svg" width="25px" viewBox="0 0 24 24" fill="none" onClick={() => showMenu()} color={theme.colors.white.pureWhite}>
				<path d="M4 18L20 18" stroke="#fff" strokeWidth="2" strokeLinecap="round" />
				<path d="M4 12L20 12" stroke="#fff" strokeWidth="2" strokeLinecap="round" />
				<path d="M4 6L20 6" stroke="#fff" strokeWidth="2" strokeLinecap="round" />
			</svg>

			<Modal title="Menu" display={state.menuModal} handleClose={showMenu}>
				<ModalInner>
					<Top>
						{user ? (
							<AdminNav>
								{user.admin === 1 ? (
									<Munuitem><Link to="/admin">Dashboard</Link></Munuitem>
								) : ('')}
								<Munuitem><Link to="/bookings">Bookings</Link></Munuitem>
								<Munuitem onClick={() => showProfile()} color={theme.colors.white.pureWhite}>Profile</Munuitem>
								<Munuitem><Link to="/stats">Stats</Link></Munuitem>
								<Munuitem onClick={() => _logout()} color={theme.colors.white.pureWhite}>Logout</Munuitem>
							</AdminNav>
						) : (
							props.date ? ('') : (
								<AdminNav>
									<Munuitem><Link to="/bookings">Bookings</Link></Munuitem>
								</AdminNav>
							)
						)}
					</Top>
					<Bottom>
						{/* <Event>
							<Title variant='t2' className="smalltitle" color='#009bff'>Klub Kampioenskappe 2024</Title>
							<InfoBlock>
								<Title variant='t4' color='#000'>Datum:</Title>
								<Paragraph color="#000">14 Sep - 24 Ock</Paragraph>
							</InfoBlock>

							<InfoBlock>
								<Title variant='t4' color='#000'>Fooi:</Title>
								<Paragraph color="#000">R50ps</Paragraph>
							</InfoBlock>
							<InfoBlock>
								<Title variant='t4' color='#000'>Bank:</Title>
								<Paragraph color="#000">Malmesbury Muurbal Klub</Paragraph>

							</InfoBlock>


							<InfoBlock>
								<Title variant='t4' color='#000'> </Title>
								<Paragraph color="#000">Standard Bank</Paragraph>
							</InfoBlock>
							<InfoBlock>
								<Title variant='t4' color='#000'> </Title>
								<Paragraph color="#000">rek: 186113153</Paragraph>
							</InfoBlock>
							<InfoBlock>
								<Title variant='t4' color='#000'> </Title>
								<Paragraph color="#000">bewys: Klub+Naam en Van</Paragraph>
							</InfoBlock>


							{user && state.entries.includes(user.member_number) ? (

								<InfoBlock>
									<Title variant='t4' color='#000'> </Title>
									<Paragraph color="green">
										Sien jou daar :)</Paragraph>
								</InfoBlock>


							) : (
								<Button className="enter" title="Sign up" type='button' colors={{ backgroundColor: theme.colors.blue.blue, color: theme.colors.white.pureWhite }} onClick={() => _handleEnter()} />
							)}
						</Event> */}


					</Bottom>
				</ModalInner>
			</Modal>

			<Modal title="Member" display={state.memberModal} handleClose={showProfile} handleSubmit={handleSubmit}>
				<Form onSubmit={handleSubmit}>
					{formData.first_name}
					<input type="text" name="first_name" value={formData.first_name} placeholder='First name' onChange={handleChange} />
					<input type="text" name="last_name" value={formData.last_name} placeholder='Last name' onChange={handleChange} />
					<input type="email" name="email" value={formData.email} placeholder='Email' onChange={handleChange} />
					<input type="number" min="10" max="10" name="contact" value={formData.contact} placeholder='Number' onChange={handleChange} />
				</Form>
			</Modal>
		</NavBar >
	);
};

export default HeaderNavigation;

