import React, { useEffect, useRef, useState } from 'react';
import Chart, { ChartConfiguration } from 'chart.js/auto';
import axios from 'axios';
import date from 'date-and-time';
import update from 'react-addons-update';
import { useAuth } from '../../services/AuthProvider';


interface MembersRadarChartProps {
    members: {
        [key: string]: any;
    };
    wins: {
        [key: string]: any;
    };
    lose: {
        [key: string]: any;
    };
}

const DEFAULT_STATE: MembersRadarChartProps = {
    members: {},
    wins: {},
    lose: {},
};

const MembersRadarChart: React.FC = () => {

    const [state, setState] = useState<MembersRadarChartProps>({ ...DEFAULT_STATE });
    const chartRef = useRef<HTMLCanvasElement | null>(null);
    const chartInstance = useRef<Chart | null>(null);
    const { user } = useAuth();

    const getRandomRgbColor = (): string => {
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);
        return `rgba(${r}, ${g}, ${b}, 0.2)`;
    };
    
    const getBookings = (id: string) => {
        axios.get(process.env.REACT_APP_API_URL + 'bookings').then((response: any) => {

            let win_booking: {
                [key: string]: any;
            } = {}

            let lose_booking: {
                [key: string]: any;
            } = {}

            let new_members: {
                [key: string]: any;
            } = {}

            let bookingsCnt = 0

            for (let i = 0; i < response.data.length; i++) {
                const booking = response.data[i]



                if (booking.member_score !== null && !booking.opponent_score !== null) {

                    // Title list
                    if (!new_members[booking.member_number]) {
                        new_members[booking.member_number] = booking.first_name + ' ' + booking.last_name
                    }
                    if (!new_members[booking.openent_member_number]) {
                        new_members[booking.openent_member_number] = booking.openent_first_name + ' ' + booking.openent_last_name
                    }

                    if (!win_booking[booking.openent_member_number]) {
                        win_booking[booking.openent_member_number] = 0
                    }
                    if (!lose_booking[booking.openent_member_number]) {
                        lose_booking[booking.openent_member_number] = 0
                    }

                    if (parseInt(booking.member_score) > parseInt(booking.opponent_score)) {
                        win_booking[booking.openent_member_number] = win_booking[booking.openent_member_number] + 1
                    } else {
                        lose_booking[booking.openent_member_number] = lose_booking[booking.openent_member_number] + 1

                    }
                    bookingsCnt++
                }
            }



            if (new_members) {
                let memberArray: any = []
                let winBookingArray: any = []
                let loseBookingArray: any = []
                for (const [key, value] of Object.entries(new_members)) {
                    memberArray.push(value)
                    winBookingArray.push(win_booking[key])
                    loseBookingArray.push(lose_booking[key])
                }
                console.log('memberArray', memberArray)
                console.log('winBookingArray', winBookingArray)
                console.log('loseBookingArray', loseBookingArray)

                if (chartRef.current) {
                    // Destroy previous chart instance if it exists
                    if (chartInstance.current) {
                        chartInstance.current.destroy();
                    }

                    const winBookingArrayMax = Math.max(...winBookingArray);
                    const loseBookingArrayMax = Math.max(...loseBookingArray);
                    const ctx = chartRef.current.getContext('2d');
                    if (ctx) {
                        const color1 = getRandomRgbColor();
                        const color2 = getRandomRgbColor();
                        const color3 = getRandomRgbColor();
                        const chartConfig: ChartConfiguration = {
                            type: 'radar',
                            data: {
                                labels: memberArray,
                                datasets: [
                                    {
                                        label: 'Arndt',
                                        data: [],
                                        backgroundColor: color1,
                                        borderColor: color1,
                                        
                                        borderWidth: 1
                                    },
                                    {
                                        label: 'Jaco',
                                        data: [],
                                        backgroundColor: color2,
                                        borderColor: color2,
                                        borderWidth: 1
                                    },
                                    {
                                        label: 'joel',
                                        data: [],
                                        backgroundColor: color3,
                                        borderColor: color3,
                                        borderWidth: 1
                                    }
                                ]
                            },
                            options: {
                                scales: {
                                    r: {
                                        angleLines: {
                                            display: false
                                        },
                                        suggestedMin: 0,
                                        suggestedMax: winBookingArrayMax >= loseBookingArrayMax ? winBookingArrayMax : loseBookingArrayMax
                                    }
                                }
                            }
                        };

                        // Create new chart instance
                        chartInstance.current = new Chart(ctx, chartConfig);
                    }
                }



                setState((prevState) =>
                    update(prevState, {
                        wins: { $set: winBookingArray },
                        lose: { $set: loseBookingArray },
                        members: { $set: memberArray },
                    })
                );
            }
        });
    }


    useEffect(() => {
        if (user?.member_number) {
            getBookings(user?.member_number)
        }
    }, [user?.member_number]);

    return (
        <canvas ref={chartRef} width={400} height={400}></canvas>
    );
};

export default MembersRadarChart;
