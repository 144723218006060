import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ResponsivePXValue } from '../Theme';
import Header from '../organisms/Header';
import MemberRadarChart from '../organisms/MemberRadarChart';
import MembersRadarChart from '../organisms/MembersRadarChart';

const Wrapper = styled.div`
	width: 100vw;
	height: 100vh;
	
`
const WrapperInner = styled.div`
	${ResponsivePXValue('padding', { mobile: '100px', tablet: '100px', desktop: '100px' })}
	justify-content: center;
	align-items: center;
	height: 80%;
	display: flex;
	canvas {
		${ResponsivePXValue('width', { mobile: '50%', tablet: '50%', desktop: '50%!important' })}
		${ResponsivePXValue('height', { mobile: '50%', tablet: '50%', desktop: 'auto!important' })}
	}
`

const StatsPage: React.FC = () => {

	useEffect(() => {

	}, []);

	return (<Wrapper>
		<Header />
		<WrapperInner>
			<MemberRadarChart />
			<MembersRadarChart />
		</WrapperInner>
	</Wrapper>);
};

export default StatsPage;
