import { BubbleController } from "chart.js/dist";

export const colors = {
	white: {
		pureWhite: '#FFF'
	},
	black: {
		pureBlack: '#000',
		transparent: 'RGBA(0, 0, 0, 0.5)'
	},
	misc: {
		transparent: 'RGBA(255, 255, 255, 0)'
	},
	blue: {
		blue: 'RGBA(1, 155, 255, 1)'
	}
};
