import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import Title from '../atoms/Title';
import date from 'date-and-time';
import update from 'react-addons-update';
import axios from 'axios';
import { ResponsivePXValue } from '../Theme';
import Paragraph from '../atoms/Paragraph';
import Header from '../organisms/Header';
import MemberLogin from '../forms/MemberLogin';
import Modal from '../organisms/Modal';
import Messages from '../atoms/Messages';
import { useAuth } from '../../services/AuthProvider';

const Wrapper = styled.div`

`

const Logos = styled.div`
	position: fixed;
	z-index: -1;
	display: flex;
	justtify-content: center;
	align-items: center;
	${ResponsivePXValue('bottom', { mobile: '15px', tablet: '50px', desktop: '50px' })}
	${ResponsivePXValue('height', { mobile: '50px', tablet: '70px', desktop: '70px' })}
	${ResponsivePXValue('gap', { mobile: '30px', tablet: '70px', desktop: '70px' })}
	${ResponsivePXValue('right', { mobile: '15px', tablet: '50px', desktop: '50px' })}
	img {
		height: 100%;
		opacity: 0.4;
	}
`

const WrapperInner = styled.div`
	position: relative;
	height: 100vh;
	overflow-y: scroll;
	overflow-x: hidden;
`

const CourstWrapper = styled.div`
`

const CourstWrapperInner = styled.div`
	display: flex;
	overflow: scroll;
	height: 90%;
	position: relative;
	${ResponsivePXValue('margin-top', { mobile: '75px', tablet: '100px', desktop: '115px' })}

	${ResponsivePXValue('gap', { mobile: '0', tablet: '20px', desktop: '20px' })}
`

const CourtWrapper = styled.div`
	${ResponsivePXValue('width', { mobile: '50%', tablet: '50%', desktop: '50%' })}
	${ResponsivePXValue('padding', { mobile: '10px', tablet: '15px', desktop: '15px' })}
	h1 {
	${ResponsivePXValue('margin-bottom', { mobile: '10px', tablet: '10px', desktop: '10px' })}
		
	}
`

const TimeWrapper = styled.div`
	box-shadow: 0px 0px 7px #ccc;
`
const Time = styled.div`
	${ResponsivePXValue('padding', { mobile: '8px', tablet: '10px', desktop: '10px' })}
	border: 1px solid #999;
	cursor: pointer;
	&:hover {
		background-color: #ccc;
	}
`

const MemberWrapper = styled.div`
	${ResponsivePXValue('padding', { mobile: '4px', tablet: '5px', desktop: '5px' })}
	
	border: 1px solid #999;
	cursor: pointer;
	background-color: #009bff;
	p {
		color: #fff!important;
	}
	&:hover {
		background-color: #ccc;
	}
`

const Member = styled.div<{ score?: string, won?: string }>`
${ResponsivePXValue('padding', { mobile: '4px', tablet: '5px', desktop: '5px' })}
	display: flex;
	justify-content: space-between;
	align-items: center;
`
const MemberB = styled.div<{ score?: string, won?: string }>`
${ResponsivePXValue('padding', { mobile: '4px', tablet: '5px', desktop: '5px' })}
	display: flex;
	justify-content: space-between;
	align-items: center;
	${(props) =>
		props.score &&
		css`
			background: rgb(60,150,31);
			background: ${(): string =>
				props.won === 'true' ? 'linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(255,255,255,0) 50%, rgba(60,150,31,1) 100%)' : 'linear-gradient(90deg, rgba(60,150,31,1) 0%, rgba(255,255,255,0) 50%, rgba(255,0,0,1) 100%)'};
		`}
	
`

const Select = styled.select`
	${ResponsivePXValue('padding', { mobile: '15px 15px 15px 0', tablet: '15px 15px 15px 0', desktop: '15px 15px 15px 0' })}
	${ResponsivePXValue('font-size', { mobile: '3vw', tablet: '20px', desktop: '20px' })}
	${ResponsivePXValue('margin-left', { mobile: '-3px', tablet: '-3px', desktop: '-3px' })}
	font-family: monospace;
	-webkit-appearnce: none;
    border: 0;
    border-top: 1px solid;
    width: -webkit-fill-available;
`
const AdminSelect = styled.select`
	${ResponsivePXValue('padding', { mobile: '15px 15px 15px 0', tablet: '15px 15px 15px 0', desktop: '15px 15px 15px 0' })}
	${ResponsivePXValue('font-size', { mobile: '3vw', tablet: '20px', desktop: '20px' })}
	${ResponsivePXValue('margin-left', { mobile: '-3px', tablet: '-3px', desktop: '-3px' })}
	font-family: monospace;
    border: 0;
    width: -webkit-fill-available;
`



const BodyWrapper = styled.div``
const VsBlock = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	border-top: 1px solid #000;
    border-bottom: 1px solid #000;
	${ResponsivePXValue('gap', { mobile: '20px', tablet: '20px', desktop: '20px' })}
`
const VsMembers = styled.div`

	${ResponsivePXValue('width', { mobile: '85%', tablet: '85%', desktop: '85%' })}
`
const ImageContainer = styled.div`
	${ResponsivePXValue('width', { mobile: '15%', tablet: '15%', desktop: '15%' })}
	img {
		width: 100%;
	}
`
const VsMember = styled.div`

	p {
	${ResponsivePXValue('margin', { mobile: '15px 0', tablet: '15px 0', desktop: '15px 0' })}

	}
`

const Input = styled.input`
	padding: 15px;
	font-size: 20px;
	${ResponsivePXValue('width', { mobile: '80px', tablet: '80px', desktop: '60%' })}
`

const ScoreWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const Score = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	${ResponsivePXValue('gap', { mobile: '10px', tablet: '10px', desktop: '10px' })}
`

const TimeArm = styled.div<{ top: number }>`
		top: ${(props): number => props.top }%;
		position: absolute;
		left: 50%;
		width: 20px;
		justify-content: center;
		align-items: center;
		display: flex;
		margin-left: -10px;
		margin-top: 15px;
		${ResponsivePXValue('font-size', { mobile: '15px', tablet: '30px', desktop: '30px' })}
`

interface BookingsPageProps {
	date: Date;
	bookingModal: boolean;
	cancelBookingModal: boolean;
	updateBookingModal: boolean;
	loginModal: boolean;
	time: string;
	member_score: string;
	opponent_score: string;
	session: string | null
	password: string;
	court: string;
	member_id: string;
	oponent_number: string;
	member_number: string;
	bookings: {
		[key: string]: any;
	};
	booking: {
		[key: string]: any;
	};
	members: [];
	message: {
		[key: string]: any;
	};
}

const DEFAULT_STATE: BookingsPageProps = {
	date: new Date(),
	bookingModal: false,
	member_score: '',
	opponent_score: '',
	cancelBookingModal: false,
	updateBookingModal: false,
	loginModal: false,
	session: sessionStorage.getItem('session'),
	member_id: '',
	oponent_number: '',
	member_number: '',
	password: '',
	time: '',
	court: '',
	bookings: {},
	booking: {},
	members: [],
	message: {}
};

const BookingsPage: React.FC = () => {

	const theme = useTheme();
	const [state, setState] = useState<BookingsPageProps>({ ...DEFAULT_STATE });
	const courts = ['1', '2']
	let nowHour = date.format(new Date(), 'HH');
	const times = [
		'00:00',
		'00:45',
		'01:30',
		'02:15',
		'03:00',
		'03:45',
		'04:30',
		'05:15',
		'06:00',
		'06:45',
		'07:30',
		'08:15',
		'09:00',
		'09:45',
		'10:30',
		'11:15',
		'12:00',
		'12:45',
		'13:30',
		'14:15',
		'15:00',
		'15:45',
		'16:30',
		'17:15',
		'18:00',
		'18:45',
		'19:30',
		'20:15',
		'21:00',
		'21:45',
		'22:30',
		'23:15',
	]
	const { user } = useAuth();

	const elementRef = useRef<HTMLDivElement>(null);

	const scrollToElement = () => {
	  if (elementRef.current) {
		elementRef.current.scrollIntoView({ behavior: 'smooth' });
	  }
	};

	const _setDate = (date: Date) => {
		setState((prevState) =>
			update(prevState, {
				date: { $set: date }
			})
		);
	}

	const _handleTimeClick = (court: string, time: string) => {

		let nowDate = date.format(new Date(), 'YYYY-MM-DD');
		let nowTime = date.format(new Date(), 'HH:MM');
		if (state.bookings[date.format(state.date, 'YYYY-MM-DD')]
			&& state.bookings[date.format(state.date, 'YYYY-MM-DD')][court]
			&& state.bookings[date.format(state.date, 'YYYY-MM-DD')][court][time]) {
			const booking = state.bookings[date.format(state.date, 'YYYY-MM-DD')][court][time];
			if (user?.member_number === booking.member_number || user?.member_number === booking.openent_member_number) {
				let dateRaw = date.format(state.date, 'YYYY-MM-DD');

				const date1 = new Date(date.format(new Date(booking.date), 'YYYY-MM-DD') + `T${booking.time}Z`);
				const date2 = new Date(nowDate + `T${nowTime}Z`);

				if (nowDate >= dateRaw && date1 < date2) {
					if (!booking.openent_member_number) {
						return false;
					}
					setState((prevState) =>
						update(prevState, {
							booking: { $set: booking },
							member_score: { $set: booking.member_score || '' },
							opponent_score: { $set: booking.opponent_score || '' },
							time: { $set: time },
							court: { $set: court },
							updateBookingModal: { $set: true }
						})
					);
				} else {
					setState((prevState) =>
						update(prevState, {
							booking: { $set: booking },
							time: { $set: time },
							court: { $set: court },
							cancelBookingModal: { $set: true }
						})
					);
				}
			}
		} else {
			setState((prevState) =>
				update(prevState, {
					time: { $set: time },
					court: { $set: court },
					bookingModal: { $set: true }
				})
			);
		}
	};

	const _handleBookingClose = () => {
		setState((prevState) =>
			update(prevState, {
				time: { $set: '' },
				court: { $set: '' },
				member_id: { $set: '' },
				member_number: { $set: user?.member_number },
				oponent_number: { $set: '' },
				bookingModal: { $set: false },
				cancelBookingModal: { $set: false },
				updateBookingModal: { $set: false },
				loginModal: { $set: false },
				member_score: { $set: '' },
				opponent_score: { $set: '' }
			})
		);
	}

	const _handleBooking = () => {

		setState((prevState) =>
			update(prevState, {
				message: {
					$set: {}
				},
			})
		);
		axios.post(process.env.REACT_APP_API_URL + 'bookings',
			{
				member_id: state.member_number || user?.member_number,
				court: state.court,
				time: state.time,
				oponent_number: state.oponent_number,
				date: date.format(state.date, 'YYYY-MM-DD')
			})
			.then(response => {
				console.log('response', response)
				if (response.status === 201) {
					setState((prevState) =>
						update(prevState, {
							bookingModal: { $set: false },
							member_id: { $set: '' },
							oponent_number: { $set: '' },
							member_number: { $set: user?.member_number }
						})
					);
				} else {
					setState((prevState) =>
						update(prevState, {
							message: {
								$set: {
									type: 'error',
									message: 'Oops, Something went wrong. Please try again later.'
								}
							},
						})
					);

				}
				getBookings();
			})
			.catch(error => {
				setState((prevState) =>
					update(prevState, {
						message: {
							$set: {
								type: 'error',
								message: 'Oops, Something went wrong. '+error.response.data.error
							}
						},
					})
				);
			});
	};

	const _handleCancelBooking = () => {

		axios.post(process.env.REACT_APP_API_URL + 'bookings/' + state.booking.id, {})
			.then(response => {
				setState((prevState) =>
					update(prevState, {
						booking: { $set: {} },
						time: { $set: '' },
						member_id: { $set: '' },
						court: { $set: '' },
						bookingModal: { $set: false },
						cancelBookingModal: { $set: false }
					})
				);
				getBookings()
			})
			.catch(error => {
				console.log('error', error)
			});
	};

	const _handleUpdateBooking = () => {
		axios.post(process.env.REACT_APP_API_URL + 'booking/' + state.booking.id, {
			member_score: state.member_score,
			opponent_score: state.opponent_score
		})
			.then(response => {
				setState((prevState) =>
					update(prevState, {
						booking: { $set: {} },
						time: { $set: '' },
						member_score: { $set: '' },
						opponent_score: { $set: '' },
						member_id: { $set: '' },
						court: { $set: '' },
						bookingModal: { $set: false },
						cancelBookingModal: { $set: false },
						updateBookingModal: { $set: false }
					})
				);
				getBookings()
			})
			.catch(error => {
				console.log('error', error)
			});

	};

	const slotValue = (court: string, time: string) => {
		if (state.bookings[date.format(state.date, 'YYYY-MM-DD')]
			&& state.bookings[date.format(state.date, 'YYYY-MM-DD')][court]
			&& state.bookings[date.format(state.date, 'YYYY-MM-DD')][court][time]) {
			if (state.bookings[date.format(state.date, 'YYYY-MM-DD')][court][time].openent_member_number) {

				return <Member score={state.bookings[date.format(state.date, 'YYYY-MM-DD')][court][time].member_score} won={(state.bookings[date.format(state.date, 'YYYY-MM-DD')][court][time].member_score < state.bookings[date.format(state.date, 'YYYY-MM-DD')][court][time].opponent_score).toString()}>
					<span>{state.bookings[date.format(state.date, 'YYYY-MM-DD')][court][time].first_name}</span>
					<span>VS</span>
					<span>{state.bookings[date.format(state.date, 'YYYY-MM-DD')][court][time].openent_first_name}</span>
				</Member>
			} else {
				return <Member >{state.bookings[date.format(state.date, 'YYYY-MM-DD')][court][time].first_name + " " + state.bookings[date.format(state.date, 'YYYY-MM-DD')][court][time].last_name}</Member>
			}
		}
		return time
	}

	const slotTaken = (court: string, time: string) => {
		if (state.bookings[date.format(state.date, 'YYYY-MM-DD')]
			&& state.bookings[date.format(state.date, 'YYYY-MM-DD')][court]
			&& state.bookings[date.format(state.date, 'YYYY-MM-DD')][court][time]) {
			return true
		}
		return false
	}

	const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
		const { name, value } = e.target;
		setState((prevState) =>
			update(prevState, {
				[name]: { $set: value },
				member_id: { $set: '' }
			})
		);
	};

	const getBookings = () => {
		axios.get(process.env.REACT_APP_API_URL + 'bookings').then((response: any) => {
			let new_booking: {
				[key: string]: any;
			} = {}
			for (let i = 0; i < response.data.length; i++) {
				let dateString = date.format(new Date(response.data[i].date), 'YYYY-MM-DD')
				if (!new_booking[dateString]) {
					new_booking[dateString] = {}
				}
				if (!new_booking[dateString][response.data[i].court]) {
					new_booking[dateString][response.data[i].court] = {}
				}
				new_booking[dateString][response.data[i].court][response.data[i].time] = response.data[i]
			}
			setState((prevState) =>
				update(prevState, {
					bookings: { $set: new_booking },
					bookingModal: { $set: false }
				})
			);
		});
	}

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setState((prevState) =>
			update(prevState, {
				[name]: { $set: value }
			})
		);
	};

	useEffect(() => {
		axios.get(process.env.REACT_APP_API_URL + 'members').then((response: any) => {
			setState((prevState) =>
				update(prevState, {
					members: { $set: response.data },
				})
			);
		});
		getBookings()
		
		
	}, []);

	useEffect(() => {
		if (user?.member_number) {
			setState((prevState) =>
				update(prevState, {
					member_number: { $set: user?.member_number }
				})
			);
		}
		scrollToElement()
	}, [user?.member_number]);

	return (<Wrapper><WrapperInner>

		<Header date={state.date} setDate={_setDate} />
		<Logos>
			<img src="jaco-logo.png" alt="jaco" />
			<img src="logo.jpg" alt="logo" />
		</Logos>
		{user ? (
			<BodyWrapper>
				<CourstWrapper>
					<CourstWrapperInner>
						<TimeArm ref={elementRef} top={(parseInt(nowHour)/24*100)}>:</TimeArm>
						{courts.map((court, courtIndex) => (
							<CourtWrapper key={courtIndex}>
								<Title variant="t3" color={theme.colors.black.pureBlack}>Court {court}</Title>
								<TimeWrapper>
									{times.map((time, timesIndex) => (
										slotTaken(court, time) ? (
											<MemberWrapper key={courtIndex + '_' + timesIndex} onClick={() => _handleTimeClick(court, time)}><Paragraph color="#000" variant={'p1'} >{slotValue(court, time)}</Paragraph></MemberWrapper>
										) : (
											<Time key={courtIndex + '_' + timesIndex} onClick={() => _handleTimeClick(court, time)}><Paragraph color="#000" variant={'p1'} >{time}</Paragraph></Time>
										)
									))}
								</TimeWrapper>
							</CourtWrapper>
						))}
					</CourstWrapperInner>
				</CourstWrapper>
			</BodyWrapper>
		) : (
			<MemberLogin />
		)}

		<Modal title="New booking" display={state.bookingModal} handleClose={_handleBookingClose} handleSubmit={_handleBooking} >
			<Paragraph variant="p1" color="#000">Date: {date.format(state.date, 'dd DD MMMM YYYY')}</Paragraph>
			<Paragraph variant="p1" color="#000">Time: {state.time}</Paragraph>

			<VsBlock>
				<ImageContainer>
					<img alt="vs" src="vs.png" />
				</ImageContainer>
				<VsMembers>
					{user?.admin === 1 ? (
							<AdminSelect value={state.member_number} name="member_number" onChange={handleSelectChange}>
								<option value="">Memeber / Liga</option>
								{state.members.map((member: any, memberIndex: number) => (
									member.member_number === user?.member_number || member.member_type == 5 ? 
									<option key={memberIndex} value={member.member_number}>{member.first_name} {member.last_name}</option>
									: ('')
								))}
							</AdminSelect>
					) : (
						<VsMember><Paragraph variant="p1" color="#000">{user?.first_name} {user?.last_name}</Paragraph></VsMember>
					)}

					< VsMember >
						<Select value={state.oponent_number} name="oponent_number" onChange={handleSelectChange}>
							<option value="">Opponent</option>
							{state.members.map((member: any, memberIndex: number) => (
								user?.member_number !== member.member_number ? (
								<option key={memberIndex} value={member.member_number}>{member.first_name} {member.last_name}</option>
								) : ('')
							))}
						</Select>
					</VsMember>
				</VsMembers>
			</VsBlock>
			<Messages message={state.message} />
		</Modal>

		<Modal title="Cancel booking" display={state.cancelBookingModal} handleClose={_handleBookingClose} handleSubmit={_handleCancelBooking} >
			<Paragraph color="#000">Date: {date.format(state.date, 'dd DD MMMM YYYY')}</Paragraph>
			<Paragraph color="#000">Time: {state.time}</Paragraph>
		</Modal>

		<Modal title="Update score" display={state.updateBookingModal} handleClose={_handleBookingClose} handleSubmit={_handleUpdateBooking} >
			<ScoreWrapper>
				<Score>
					<Paragraph color="#000" variant='p2'>{state.booking.first_name}</Paragraph>
					<Input type='number' name="member_score" placeholder='Score' value={state.member_score} onChange={handleChange} />
				</Score>
				<Score><img width="20px" alt="vs" src="vs.png" /></Score>
				<Score>
					<Paragraph color="#000" variant='p2'>{state.booking.openent_first_name}</Paragraph>
					<Input type='number' name="opponent_score" placeholder='Score' value={state.opponent_score} onChange={handleChange} />
				</Score>
			</ScoreWrapper>

		</Modal>

	</WrapperInner>
	</Wrapper >);
};

export default BookingsPage;
